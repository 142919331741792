import { template as template_8a1e1148b44642278b9c2fb378123749 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_8a1e1148b44642278b9c2fb378123749(`
    {{pageTitle (t "results")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
