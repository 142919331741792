import { template as template_dabd9f51721e4ceea6294dfa18cbf481 } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { hasInProgressInstanceOfCurrentProgramTemplate } from 'tio-common/utils/tuition-assistance/tas-grouping';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceProgramDetailsOverview from 'tio-employee/components/tuition-assistance/program-details/overview';
import type IntlService from 'ember-intl/services/intl';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import { Header, Section, VStack } from 'tio-ui/components/layout';
export interface S {
    Args: {
        model: {
            activeInstances: TASProgramInstanceModel[];
            programTemplate: TasProgramTemplate;
            tasParticipant: TasParticipantModel;
            tasGroups: TasGroupModel[];
        };
    };
}
class AuthenticatedTuitionAssistanceProgramOverview extends Component<S> {
    @service
    tuitionAssistance: TuitionAssistanceService;
    @service
    intl: IntlService;
    get isActiveEligibility() {
        return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.args.model.programTemplate);
    }
    get isMultipleInstanceProgram() {
        return this.args.model.programTemplate.isMultipleInstanceProgram;
    }
    get isRestrictedByProgramExclusivity() {
        if (this.args.model.tasGroups.length === 0) {
            return false;
        }
        return hasInProgressInstanceOfCurrentProgramTemplate(this.args.model.tasGroups, this.args.model.programTemplate.id, this.args.model.activeInstances);
    }
    get ineligibleBasedOnWaitingPeriod() {
        return this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(this.args.model.programTemplate.eligibilityWaitingPeriod, this.args.model.tasParticipant);
    }
    get waitingPeriod() {
        return String(getSingleValueForTasField('ELIGIBILITY_WAITING_PERIOD', this.args.model.programTemplate.fields) || 0);
    }
    get dateEligible() {
        const date = this.tuitionAssistance.waitingPeriodEndDate(this.args.model.programTemplate.eligibilityWaitingPeriod, this.args.model.tasParticipant);
        return this.intl.formatDate(date, {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }
    static{
        template_dabd9f51721e4ceea6294dfa18cbf481(`
    {{pageTitle
      (concat
        @model.programTemplate.programName " | " (t "tuition_assistance.program_details.default")
      )
    }}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.program-overview"
          @label={{t "tuition_assistance.program_details.default"}}
        />
      </TioPageBreadcrumbs>

      <Section>
        <:body>
          {{#if @model.tasParticipant.isHeld}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          {{#unless this.isActiveEligibility}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/unless}}

          {{#if this.isRestrictedByProgramExclusivity}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.program_exclusivity_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.program_exclusivity_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          {{#if this.ineligibleBasedOnWaitingPeriod}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.program_eligibility_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t
                    "tuition_assistance.program_eligibility_warnings.dashboard_description"
                    time=this.waitingPeriod
                    date=this.dateEligible
                    htmlSafe=true
                  }}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          <TuitionAssistanceProgramDetailsOverview
            @programTemplate={{@model.programTemplate}}
            @isHeldParticipant={{@model.tasParticipant.isHeld}}
            @isActiveParticipant={{this.isActiveEligibility}}
            @isRestrictedByProgramExclusivity={{this.isRestrictedByProgramExclusivity}}
            @ineligibleBasedOnWaitingPeriod={{this.ineligibleBasedOnWaitingPeriod}}
          />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AuthenticatedTuitionAssistanceProgramOverview);
