import { template as template_ed54a088f44e4ca38d73a61444c1fe61 } from "@ember/template-compiler";
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_ed54a088f44e4ca38d73a61444c1fe61(`
    <div>
      <QuestionsLoanForgiveness
        @indexRoute="authenticated.questions-you-have.loan-forgiveness.index"
        @incomeRoute="authenticated.questions-you-have.loan-forgiveness.income-driven"
        @teacherRoute="authenticated.questions-you-have.loan-forgiveness.teacher"
        @stateRoute="authenticated.questions-you-have.loan-forgiveness.state-and-local"
      >
        {{outlet}}
      </QuestionsLoanForgiveness>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
