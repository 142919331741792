import { template as template_7b8c8284c4874ddc8bae0086d2fb068e } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import type EmployeeModel from 'tio-common/models/employee';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type SessoionContextService from 'tio-employee/services/session-context';
import { Input } from 'tio-ui/components/forms';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
export interface TuitionAssistanceFormsMentoringSignature {
    Args: {
        employee: EmployeeModel;
    };
}
export default class TuitionAssistanceFormsMentoring extends Component<TuitionAssistanceFormsMentoringSignature> {
    @service
    sessionContext: SessoionContextService;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @tracked
    topic = null;
    @tracked
    previousCredentials = '';
    @tracked
    preferredFormat?: string;
    @tracked
    previousLoans?: string;
    @tracked
    educationType = '';
    @tracked
    currentlyEnrolled?: string;
    @tracked
    institutionCounselor?: string;
    @tracked
    preferredContactMethod?: string;
    @tracked
    submissionError = false;
    get employee() {
        return this.args.employee;
    }
    get plainText() {
        const employeeInfo = {
            firstName: this.employee.firstName,
            lastName: this.employee.lastName,
            id: this.employee.id,
            email: this.employee.email
        };
        const answers = {
            scheduling_question_topic: this.topic,
            previous_relevant_credentials: this.previousCredentials,
            preferred_learning_format: this.preferredFormat,
            previous_loans: this.previousLoans,
            type_of_education: this.educationType,
            currently_enrolled: this.currentlyEnrolled,
            counselor_at_selected_institution: this.institutionCounselor,
            preferred_contact_method: this.preferredContactMethod,
            ...employeeInfo
        };
        let plainText = '';
        Object.keys(answers).forEach((question)=>{
            // @ts-expect-error: not sure about this one
            if (answers[question]) {
                // @ts-expect-error: not sure about this one
                plainText += ` ${question}: ${answers[question]}.` + '\n';
            }
        });
        return plainText;
    }
    get submitEnabled() {
        const fieldsPresent = this.topic && this.previousCredentials && this.preferredFormat && this.previousLoans && this.educationType && this.currentlyEnrolled && this.institutionCounselor && this.preferredContactMethod;
        if (!fieldsPresent) {
            return false;
        } else {
            return true;
        }
    }
    // TODO: Refactor to use a dropTask so we can add loading indicator for button,
    // prevent multiple submissions from button smashing, and give user more feedback once complete
    @action
    async submitRequest() {
        const headers = {
            'x-api-key': ENV.apiKey,
            'tio-auth-token': this.sessionContext.session.data.authenticated.access_token,
            'Content-Type': 'application/json'
        };
        const url = `${ENV.apiHost}/coaching/schedule-tas`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                mentorRequestContent: this.plainText
            }),
            headers: headers
        });
        if (!response.ok) {
            this.submissionError = true;
            return;
        } else {
            this.submissionError = false;
            if (this.preferredContactMethod === 'Phone') {
                // slight delay allows this to work in safari
                setTimeout(()=>{
                    const newWindow = window.open('https://calendly.com/ta-mentoring', '_blank', 'noopener,noreferrer');
                    if (newWindow) {
                        newWindow.opener = null;
                    }
                }, 0);
            }
            this.router.transitionTo('authenticated.tuition-assistance.dashboard', {
                queryParams: {
                    formSubmitted: true
                }
            });
        }
    }
    static{
        template_7b8c8284c4874ddc8bae0086d2fb068e(`
    <div class="p-2 ml-4 max-w-2xl">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_1"}}</p>
      {{! @glint-expect-error: need to research how to appease frontile }}
      <Input @onInput={{fn (mut this.topic)}} @size="lg" class="ml-3" />
    </div>
    <div class="p-2 ml-4 max-w-2xl">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_2"}}</p>
      <Input @onInput={{fn (mut this.previousCredentials)}} @size="lg" class="ml-3" />
    </div>
    <div class="p-2 ml-4">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_3"}}</p>
      <FormRadioGroup
        data-legacy-input
        class="ml-3"
        @value={{this.preferredFormat}}
        @size="lg"
        {{! @glint-expect-error: need to research how to appease frontile }}
        @onChange={{fn (mut this.preferredFormat)}}
        as |Radio|
      >
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Online" @label="Online" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="In Person" @label="In Person" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Hybrid" @label="Hybrid" />
      </FormRadioGroup>
    </div>
    <div class="p-2 ml-4">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_4"}}</p>
      <FormRadioGroup
        data-legacy-input
        class="ml-3"
        @value={{this.previousLoans}}
        @size="lg"
        {{! @glint-expect-error: need to research how to appease frontile }}
        @onChange={{fn (mut this.previousLoans)}}
        as |Radio|
      >
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Private" @label="Private" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Federal" @label="Federal" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Both" @label="Both" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="I don't have student loans" @label="I don't have student loans" />
      </FormRadioGroup>
    </div>
    <div class="p-2 ml-4 max-w-2xl">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_5"}}</p>
      <Input @onInput={{fn (mut this.educationType)}} @size="lg" class="ml-3" />
    </div>
    <div class="p-2 ml-4">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_6"}}</p>
      <FormRadioGroup
        data-legacy-input
        class="ml-3"
        @value={{this.currentlyEnrolled}}
        @size="lg"
        {{! @glint-expect-error: need to research how to appease frontile }}
        @onChange={{fn (mut this.currentlyEnrolled)}}
        as |Radio|
      >
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="No" @label="No" />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Yes" @label="Yes" />
      </FormRadioGroup>
    </div>
    <div class="p-2 ml-4 max-w-2xl">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_7"}}</p>
      <Input @onInput={{fn (mut this.institutionCounselor)}} @size="lg" class="ml-3" />
    </div>
    <div class="p-2 ml-4">
      <p class="py-2">{{t "tuition_assistance.mentoring.question_8"}}</p>
      <FormRadioGroup
        data-legacy-input
        class="ml-3"
        @value={{this.preferredContactMethod}}
        @size="lg"
        {{! @glint-expect-error: need to research how to appease frontile }}
        @onChange={{fn (mut this.preferredContactMethod)}}
        as |Radio|
      >
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Email" @label={{t "tuition_assistance.mentoring.send_me_an_email"}} />
        {{! @glint-expect-error: need to research how to appease frontile }}
        <Radio @value="Phone" @label={{t "tuition_assistance.mentoring.schedule_phone_call"}} />
      </FormRadioGroup>
    </div>
    <div class="flex justify-center py-10">
      <Button
        @intent="primary"
        {{on "click" this.submitRequest}}
        disabled={{not this.submitEnabled}}
      >
        {{t "submit"}}
      </Button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
