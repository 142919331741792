import { template as template_18cea817778f42f19a9f8e8de23b14a4 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import type SessionContextService from 'tio-employee/services/session-context';
import { service } from '@ember/service';
import { Section } from 'tio-ui/components/layout';
interface RouteSignature {
    Args: {
        model: TasProgramInstanceModel;
    };
}
class TuitionAssistanceProgramInstancesShowReadonlyRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    static{
        template_18cea817778f42f19a9f8e8de23b14a4(`
    <Section>
      <div class="flex gap-5">
        <div class="flex flex-col gap-5">
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.employee.id}}
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @isReadonlyView={{true}}
            @requestingApp="employee"
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model}}
            @showAttachments={{true}}
          />
        </div>
        <div class="flex flex-col md:flex-row gap-5">
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramTemplate}}
          />
        </div>
      </div>

      <TuitionAssistanceProgramDetailsAppDetailsAppHistory
        @application={{@model}}
        @viewType="admin"
      />
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceProgramInstancesShowReadonlyRouteComponent);
