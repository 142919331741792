import { template as template_b1149c1277374a369040d9b9073d3258 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { LinkTo } from '@ember/routing';
import type { ComponentLike } from '@glint/template';
import { service } from '@ember/service';
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import ObservableDocumentModel from 'tio-common/models/observable-document';
import { getObservableProductRoute } from 'tio-employee/services/observability';
import type { ObservableSource } from 'tio-employee/services/observability';
import type SessionContextService from 'tio-employee/services/session-context';
import AccountSummary from 'tio-employee/components/observability/account-summary';
import TransactionsSummary from 'tio-employee/components/observability/transactions-summary';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import { eq } from 'tio-ui/utilities';
interface ObservabilityConfirmSignature {
    Args: {
        model: {
            source: ObservableSource;
            observableDocument: ObservableDocumentModel;
        };
    };
}
interface SummarySignature {
    Args: {
        document: ObservableDocumentModel;
    };
    Blocks: {
        footer?: [];
    };
    Element: HTMLElement;
}
class ObservabilityConfirm extends Component<ObservabilityConfirmSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    get employeeEmail() {
        return this.sessionContext.user?.primaryEmail?.email;
    }
    get subtitleSections() {
        const result = this.intl.t('observability.confirmation.subtitle', {
            email: this.employeeEmail
        });
        return result.split('\n');
    }
    get failedSubtitleSections() {
        const result = this.intl.t('observability.confirmation.subtitle', {
            documentType: this.documentTypeName
        });
        return result.split('\n');
    }
    get amendUrl() {
        return this.router.urlFor('authenticated.observability.dispute');
    }
    get documentType(): 'nslds' | 'statement' | 'transaction_history' | undefined {
        const { observableDocument } = this.args.model;
        switch(observableDocument.provider){
            case 'ObservableProvider.STATEMENT':
                return 'statement';
            case 'ObservableProvider.NSLDS':
                return 'nslds';
            case 'ObservableProvider.ACCOUNT_TXN_HISTORY':
                return 'transaction_history';
        }
    }
    get documentTypeName() {
        switch(this.documentType){
            case 'statement':
                return this.intl.t('observability.statement');
            case 'transaction_history':
                return this.intl.t('observability.transaction_history');
            case 'nslds':
                return this.intl.t('observability.nslds');
            default:
                return this.intl.t('observability.default_document');
        }
    }
    get transactionHistoryFailed() {
        const { observableDocument, source } = this.args.model;
        const conditions = [
            [
                'syf_payments'
            ].includes(source),
            observableDocument.extractionState === 'ExtractionState.PROCESSED',
            observableDocument.reflectionState === 'ReflectionState.NOT_APPLIED'
        ];
        return conditions.every(Boolean);
    }
    get manualTransactionUrl() {
        // only SYF for now; generate URLS for other products here if necessary
        return this.router.urlFor('authenticated.syf.payments.new');
    }
    get showFurtherReviewStatement() {
        const { observableDocument } = this.args.model;
        return [
            observableDocument.provider === 'ObservableProvider.ACCOUNT_STATEMENT',
            observableDocument.reflectionState === 'ReflectionState.PARTIALLY_APPLIED'
        ].every(Boolean);
    }
    get summaryComponent(): ComponentLike<SummarySignature> {
        const { provider } = this.args.model.observableDocument;
        switch(provider){
            case 'ObservableProvider.ACCOUNT_TXN_HISTORY':
                return TransactionsSummary;
            case 'ObservableProvider.ACCOUNT_STATEMENT':
            case 'ObservableProvider.NSLDS':
            case 'ObservableProvider.AMBIGUOUS':
            default:
                return AccountSummary;
        }
    }
    static{
        template_b1149c1277374a369040d9b9073d3258(`
    <VStack>
      {{#if this.transactionHistoryFailed}}
        <Section>
          <:header>{{t
              "observability.confirmation.failed_title"
              documentType=this.documentTypeName
            }}</:header>
          <:body>
            <p class="my-4">{{t
                "observability.confirmation.failed_subtitle"
                documentType=this.documentTypeName
              }}</p>
            <p class="my-4"><ActionableTranslation
                @t="observability.confirmation.manual_prompt"
                @links={{hash manualPaymentsLink=(hash t="here" href=this.manualTransactionUrl)}}
              /></p>
          </:body>
        </Section>
      {{else}}
        <Header>{{t "observability.confirmation.header" provider=this.documentType}}</Header>
        <Section>
          <:header>{{t "observability.confirmation.title" provider=this.documentType}}</:header>
          <:body>
            {{#each this.subtitleSections as |section|}}
              <p class="my-4">{{section}}</p>
            {{/each}}
            {{#let (component this.summaryComponent) as |SummaryComponent|}}
              <SummaryComponent
                @document={{@model.observableDocument}}
                class="w-full md:w-2/3 my-8"
              >
                <:footer>
                  <ActionableTranslation
                    @t="observability.confirmation.amend"
                    @links={{hash clickHere=(hash t="click_here" href=this.amendUrl)}}
                  />
                </:footer>
              </SummaryComponent>
            {{/let}}
            {{#if this.showFurtherReviewStatement}}
              <p class="my-8">
                {{t "observability.document.summary.partially_applied"}}
              </p>
            {{/if}}
            {{#if (eq @model.source "syf_enrollment")}}
              <LinkTo
                @route="authenticated.syf.linking-confirmation"
                @model={{@model.observableDocument.financialInstitution}}
                class="tio-btn tio-btn-primary"
              >
                {{t "observability.confirmation.continue" source=@model.source}}
              </LinkTo>
            {{else if (eq @model.source "syf_payments")}}
              <LinkTo @route="authenticated.syf.dashboard" class="tio-btn tio-btn-primary">
                {{t "observability.confirmation.continue" source=@model.source}}
              </LinkTo>
            {{else}}
              <LinkTo
                @route={{getObservableProductRoute @model.source}}
                class="tio-btn tio-btn-primary"
              >
                {{t "observability.confirmation.continue" source=@model.source}}
              </LinkTo>
            {{/if}}
          </:body>
        </Section>
      {{/if}}
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ObservabilityConfirm);
