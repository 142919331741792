export const eligibilityFileStatuses = {
  STARTED: 'STARTED',
  WAITING_FOR_UPLOAD: 'WAITING_FOR_UPLOAD',
  VALIDATING_ELIGIBILITY_FILE: 'VALIDATING_ELIGIBILITY_FILE',
  REQUIRES_USER_APPROVAL: 'REQUIRES_USER_APPROVAL',
  REQUIRES_REVIEWER_APPROVAL: 'REQUIRES_REVIEWER_APPROVAL',
  EXECUTING_TASKS: 'EXECUTING_TASKS',
  COMPLETED: 'COMPLETED',
  ABORTED_BY_USER: 'ABORTED_BY_USER',
  ABORTED_BY_REVIEWER: 'ABORTED_BY_REVIEWER',
  ABORTED_FOR_STALENESS: 'ABORTED_FOR_STALENESS',
  ABORTED_AS_DUPLICATE: 'ABORTED_AS_DUPLICATE',
  ABORTED_BY_ADMIN: 'ABORTED_BY_ADMIN',
  ERROR_REPORTED: 'ERROR_REPORTED',
  FAILED_VALIDATION: 'FAILED_VALIDATION',
  QUEUED: 'QUEUED',
  QUEUED_OFFHOURS: 'QUEUED_OFFHOURS',
};

const eligibilityFileStatusesDisplayNames = {
  ALL: 'All',
  EXECUTING_TASKS: 'Processing',
  FAILED_VALIDATION: 'Failed Validation',
  REQUIRES_REVIEWER_APPROVAL: 'Ready for Processing (Reviewer Approval)',
  QUEUED_OFFHOURS: 'Queued (Off Hours)',
  QUEUED: 'Queued',
  REQUIRES_USER_APPROVAL: 'Ready for Processing (User Approval)',
  COMPLETED: 'Completed',
  STARTED: 'Started',
  ERROR_REPORTED: 'Error Reported',
  ABORTED_AS_DUPLICATE: 'Aborted as Duplicate',
  ABORTED_BY_ADMIN: 'Aborted by Admin',
  ABORTED_BY_REVIEWER: 'Aborted by Reviewer',
  ABORTED_BY_USER: 'Aborted by User',
  ABORTED_FOR_STALENESS: 'Aborted for Staleness',
  VALIDATING_ELIGIBILITY_FILE: 'Validating Eligibility File',
  WAITING_FOR_UPLOAD: 'Waiting for Upload',
};

type EligibilityFileStatusKey = keyof typeof eligibilityFileStatusesDisplayNames;

export const statusOptions = Object.values(eligibilityFileStatusesDisplayNames);

export function statusFromDisplayName(displayName: string) {
  return Object.keys(eligibilityFileStatuses).find(
    (key) => eligibilityFileStatusesDisplayNames[key as EligibilityFileStatusKey] === displayName
  );
}

export default null; // silence a false warning
