import { template as template_133a9e996368421bb61935c406784b70 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsMentoringComponent: TOC = template_133a9e996368421bb61935c406784b70(`
  <LinkTo @route="authenticated.tuition-assistance.mentoring">
    <Tile @headerText={{t "dashboard_tiles.tuition_assistance_mentoring"}}>
      <:description>
        {{t "dashboard_tiles.mentors_help" htmlSafe=true}}
      </:description>
      <:image>
        {{svgJar
          "mentoring"
          width="80%"
          height="80%"
          role="img"
          desc=(t "svg.mentoring")
          class="lg:max-h-56 xl:max-h-full"
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsMentoringComponent;
