import { template as template_6cf85586f05e4284b7c0dd7669d8e565 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
export default RouteTemplate(template_6cf85586f05e4284b7c0dd7669d8e565(`
    <QuestionsLoanForgiveness
      @indexRoute="authenticated.pslf.questions.index"
      @incomeRoute="authenticated.pslf.questions.income-driven"
      @teacherRoute="authenticated.pslf.questions.teacher"
      @stateRoute="authenticated.pslf.questions.state-and-local"
    >
      {{outlet}}
    </QuestionsLoanForgiveness>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
