import { template as template_ef965a7baa5740888035496874385bc4 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import { or } from 'tio-ui/utilities';
import Table from 'tio-common/components/table/index';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type AccountingPaymentModel from 'tio-common/models/accounting-payment';
interface S {
    Args: {
        isLoading: boolean;
        model: AccountingPaymentModel[];
    };
    Element: HTMLTableElement;
}
const ContributionsAccountingPaymentsTable: TOC<S> = template_ef965a7baa5740888035496874385bc4(`
  <Table @isLoading={{@isLoading}} ...attributes>
    <:thead as |options|>
      <options.tr>
        <options.th>{{t "contributions.payment_date"}}</options.th>
        <options.th>{{t "contributions.applied_to_account"}}</options.th>
        <options.th>{{t "contributions.payment"}}</options.th>
      </options.tr>
    </:thead>
    <:tbody as |options|>
      {{#each @model as |payment|}}
        <options.tr>
          <options.td>
            {{safeFormatDate payment.transaction_date month="short" day="2-digit" year="numeric"}}
          </options.td>
          <options.td>
            {{or payment.servicer (t "unknown")}}
          </options.td>
          <options.td>
            {{#if payment.amount}}
              {{safeFormatNumber (divide payment.amount 100) style="currency" currency="USD"}}
            {{/if}}
          </options.td>
        </options.tr>
      {{else}}
        <options.tr>
          <options.td colspan="3">
            {{t "contributions.no_payments_found"}}
          </options.td>
        </options.tr>
      {{/each}}
    </:tbody>
  </Table>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ContributionsAccountingPaymentsTable;
