// TODO:: I am not sure we will even use these files in the future pslf.
// There are a lot of expect errors and very weird uses of random objects.
import { template as template_e733f58b9f6e43799f2dfebfa5163e40 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import MaterialIcon from 'tio-common/components/material-icon';
import type IntlService from 'ember-intl/services/intl';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
type AmountData = {
    prefix?: string;
    from?: number;
    to?: number;
    postfix?: string;
    total?: number;
};
type Industry = 'healthcare' | 'veterinarian' | 'education' | 'law' | 'other';
type Profession = 'physician' | 'dentist' | 'physician_assistant' | 'nurse_practitioner' | 'registered_nurse' | 'nurse' | 'veterinarian' | 'teacher' | 'attorney' | 'other';
export default class PslfForgivenessSearchToolComponent extends Component {
    @service
    intl: IntlService;
    /////////////////////////////////////
    //////// TRACKED VARIABLES //////////
    @tracked
    stateFilter?: {
        name: string;
        abbr: string;
    };
    @tracked
    industryFilter?: Industry;
    @tracked
    professionFilter?: Profession;
    @tracked
    viewsPerPage = {
        name: '10',
        value: 10
    };
    @tracked
    page = 1;
    /////////////////////////////////////
    ////////  DATA FOR FILTERS //////////
    fetchedprograms = trackedFunction(this, async ()=>{
        const programResponse = await fetch('/data/state-loan-forgiveness-programs.json');
        const parsedPrograms = await programResponse.json();
        return parsedPrograms;
    });
    fetchedStates = trackedFunction(this, async ()=>{
        const stateResponse = await fetch('/data/us-states.json');
        const parsedStates = await stateResponse.json();
        return parsedStates;
    });
    industryOptions = [
        'Healthcare',
        'Veterinarian',
        'Education',
        'Law',
        'Other'
    ];
    professionOptions = [
        {
            name: 'Physician',
            value: 'physician'
        },
        {
            name: 'Dentist',
            value: 'dentist'
        },
        {
            name: 'Physician Assistant',
            value: 'physician_assistant'
        },
        {
            name: 'Nurse Practitioner',
            value: 'nurse_practitioner'
        },
        {
            name: 'Registered Nurse',
            value: 'registered_nurse'
        },
        {
            name: 'Nurse',
            value: 'nurse'
        },
        {
            name: 'Veterinarian',
            value: 'veterinarian'
        },
        {
            name: 'Teacher',
            value: 'teacher'
        },
        {
            name: 'Attorney',
            value: 'attorney'
        },
        {
            name: 'Other',
            value: 'other'
        }
    ];
    /////////////////////////////////////
    ////////      GETTERS      //////////
    get programs() {
        return this.fetchedprograms.value || [];
    }
    get states() {
        return this.fetchedStates.value || [];
    }
    get viewsPerPageOptions() {
        return [
            {
                name: '5',
                value: 5
            },
            {
                name: '10',
                value: 10
            },
            {
                name: '15',
                value: 15
            },
            {
                name: 'All',
                value: this.programs.length
            }
        ];
    }
    get firstViewPosition() {
        return this.page * this.viewsPerPage.value + 1;
    }
    get lastViewPosition() {
        const lastPosition = (this.page + 1) * this.viewsPerPage.value;
        return lastPosition <= this.filteredResults.length ? lastPosition : this.filteredResults.length;
    }
    get filteredResults() {
        let colorCounter = 0;
        const programsWithStateNames = this.filterByProfession(this.filterByIndustry(this.filterByState(this.programs))).map((p)=>{
            colorCounter++;
            return {
                state: this.stateAbbrToName(p.state),
                name: p.name,
                amountForgiven: this.amountForgivenFormatter(p.amountForgiven),
                website: p.website,
                professions: this.professionFormatter(p.qualifying.professions),
                industries: p.qualifying.industries,
                eligibilityRules: p.eligibilityRules,
                'bg-color': colorCounter % 2 === 0 ? 'transparent' : 'gray-50'
            };
        });
        return programsWithStateNames;
    }
    get filteredResultsPerPage() {
        if (this.filteredResults.length <= this.viewsPerPage) {
            return this.filteredResults;
        }
        return this.filteredResults.slice(this.firstViewPosition - 1, this.lastViewPosition);
    }
    /////////////////////////////////////
    ////////      HELPERS      //////////
    stateAbbrToName(abbr: string) {
        let name;
        // @ts-expect-error: not sure
        this.states.forEach((s)=>{
            if (s.abbr == abbr) {
                name = s.name;
            }
        });
        return name;
    }
    amountForgivenFormatter(amountData: AmountData) {
        let amountString = '';
        if (amountData.prefix) {
            amountString += this.intl.t(`pslf.questions.state_and_local.search_tool.programs.${amountData.prefix}`) + ' ';
        }
        if (amountData.from) {
            amountString += `$${amountData.from.toLocaleString('en-US')} `;
        }
        if (amountData.to) {
            if (amountData.from) {
                amountString += `- `;
            }
            amountString += `$${amountData.to.toLocaleString('en-US')}`;
        }
        if (amountData.postfix) {
            amountString += ' ' + this.intl.t(`pslf.questions.state_and_local.search_tool.programs.${amountData.postfix}`);
        }
        if (amountData.total) {
            amountString += ` ($${amountData.total.toLocaleString('en-US')} total)`;
        }
        return amountString;
    }
    professionFormatter(profData: {
        name: string;
    }[]) {
        if (profData && typeof profData[0] == 'string') {
            return profData;
        }
        const profNames = profData.map((p)=>{
            return p.name;
        });
        return profNames;
    }
    filterByState(array: {
        state: string;
    }[]) {
        if (this.stateFilter) {
            return array.filter((p)=>p.state == this.stateFilter?.abbr);
        }
        return array;
    }
    // @ts-expect-error: not sure
    filterByIndustry(array) {
        if (this.industryFilter) {
            // @ts-expect-error: not sure
            return array.filter((p)=>p.qualifying.industries.includes(this.industryFilter?.toLowerCase()));
        }
        return array;
    }
    // @ts-expect-error: not sure
    filterByProfession(array) {
        if (this.professionFilter) {
            // @ts-expect-error: not sure
            return array.filter((p)=>p.professions.includes(this.professionFilter.value));
        }
        return array;
    }
    /////////////////////////////////////
    ////////      ACTIONS      //////////
    @action
    setStateFilter(state: string) {
        this.page = 0;
        // @ts-expect-error: not sure
        this.stateFilter = state;
    }
    // @ts-expect-error: not sure
    @action
    setIndustryFilter(industry) {
        this.page = 0;
        this.industryFilter = industry;
    }
    // @ts-expect-error: not sure
    @action
    setProfessionFilter(profession) {
        this.page = 0;
        this.professionFilter = profession;
    }
    // @ts-expect-error: not sure
    @action
    setViewsPerPage(vpp) {
        this.page = 0;
        this.viewsPerPage = vpp;
    }
    @action
    nextPage() {
        if (this.lastViewPosition < this.filteredResults.length) {
            this.page++;
        }
    }
    @action
    prevPage() {
        if (this.page > 0) {
            this.page--;
        }
    }
    static{
        template_e733f58b9f6e43799f2dfebfa5163e40(`
    <div>
      <div class="fles flex-col sm:grid sm:grid-cols-3">
        <span class="pr-2">
          <FormSelect
            data-legacy-input
            {{! @glint-expect-error: bug in types }}
            @allowClear={{true}}
            @label="State"
            @options={{this.states}}
            @selected={{this.stateFilter}}
            @onChange={{this.setStateFilter}}
            as |state|
          >
            {{! @glint-expect-error: bug in types }}
            {{state.name}}
          </FormSelect>
        </span>
        <span class="px-2">
          <FormSelect
            data-legacy-input
            {{! @glint-expect-error: bug in types }}
            @allowClear={{true}}
            @label="Industry"
            @options={{this.industryOptions}}
            @selected={{this.industryFilter}}
            @onChange={{this.setIndustryFilter}}
            as |industry|
          >
            {{! @glint-expect-error: bug in types }}
            {{industry}}
          </FormSelect>
        </span>
        <span class="pl-2">
          <FormSelect
            data-legacy-input
            {{! @glint-expect-error: bug in types }}
            @allowClear={{true}}
            @label="Profession"
            @options={{this.professionOptions}}
            @selected={{this.professionFilter}}
            @onChange={{this.setProfessionFilter}}
            as |state|
          >
            {{! @glint-expect-error: bug in types }}
            {{state.name}}
          </FormSelect>
        </span>
      </div>
      <div class="mt-4 border divide-y divide-gray-600 border-gray-600">
        <div class="px-2 py-4">
          {{this.filteredResults.length}}
          {{t "pslf.questions.state_and_local.search_tool.results"}}
        </div>
        {{#if (eq this.filteredResultsPerPage.length 0)}}
          <div class="p-4">
            <div>
              {{t "pslf.questions.state_and_local.search_tool.programs.no_results_1" htmlSafe=true}}
            </div>
            <div>
              {{t "pslf.questions.state_and_local.search_tool.programs.no_results_2" htmlSafe=true}}
            </div>
          </div>
        {{else}}
          {{#each this.filteredResultsPerPage as |program|}}
            <div class="p-2 bg-{{program.bg-color}}">
              <div class="flex flex-col sm:flex-row sm:justify-between h-auto">
                <div>
                  <h1 class="text-midnight text-xl font-semibold">
                    {{t
                      (concat "pslf.questions.state_and_local.search_tool.programs." program.name)
                    }}
                  </h1>
                  <div class="pt-2 sm:pt-0 sm:pl-1">
                    {{program.state}}
                  </div>
                </div>
                <a
                  class="pt-4 sm:p-4 flex flex-end text-xl text-ocean-600 font-semibold"
                  href={{program.website}}
                >
                  {{t "pslf.questions.state_and_local.search_tool.website"}}
                  <MaterialIcon @icon="open_in_new" class="pl-2 text-xl" />
                </a>
              </div>
            </div>
            <div
              class="p-4 bg-{{program.bg-color}}
                flex flex-col sm:grid sm:grid-cols-4 sm:gap-x-2 text-sm"
            >
              <div>
                <div>{{t "pslf.questions.state_and_local.search_tool.amount"}}</div>
                <div class="text-gray-500">{{program.amountForgiven}}</div>
              </div>
              <div>
                <div class="pt-2 sm:pt-0">
                  {{t "pslf.questions.state_and_local.search_tool.professions"}}
                </div>
                <ul class="pl-5 list-disc">
                  {{#each program.professions as |profession|}}
                    <li>
                      <div class="text-gray-500">
                        {{t
                          (concat
                            "pslf.questions.state_and_local.search_tool.programs.profession_"
                            profession
                          )
                        }}
                      </div>
                    </li>
                  {{/each}}
                </ul>
              </div>
              <div>
                <div class="pt-2 sm:pt-0">
                  {{t "pslf.questions.state_and_local.search_tool.industries"}}
                </div>
                <ul class="pl-5 list-disc">
                  {{#each program.industries as |industry|}}
                    <li>
                      <div class="text-gray-500">{{industry}}</div>
                    </li>
                  {{/each}}
                </ul>
              </div>
              <div>
                <div class="pt-2 sm:pt-0">{{t
                    "pslf.questions.state_and_local.search_tool.rules"
                  }}</div>
                <ul>
                  {{#each program.eligibilityRules as |rule|}}
                    <li>
                      <div class="text-gray-500">
                        {{t (concat "pslf.questions.state_and_local.search_tool.programs." rule)}}
                      </div>
                    </li>
                  {{/each}}
                </ul>
              </div>
            </div>
          {{/each}}
        {{/if}}

        <div class="px-2 py-4 flex justify-end">
          <span class="p-3">
            {{t "pslf.questions.state_and_local.search_tool.views_per_page"}}
          </span>
          <span class="px-4">
            <FormSelect
              data-legacy-input
              class="w-20"
              @searchField="name"
              @options={{this.viewsPerPageOptions}}
              @selected={{this.viewsPerPage}}
              @onChange={{this.setViewsPerPage}}
              as |view|
            >
              {{! @glint-expect-error: bug in types }}
              {{view.name}}
            </FormSelect>
          </span>
          <span class="p-3">
            {{t
              "pslf.questions.state_and_local.search_tool.first_to_last"
              first=this.firstViewPosition
              last=this.lastViewPosition
            }}
          </span>
          <span class="p-3 pl-0">
            {{t
              "pslf.questions.state_and_local.search_tool.of_total"
              total=this.filteredResults.length
            }}
          </span>
          <span class="p-3">
            <Button {{on "click" this.prevPage}} @appearance="outlined" disabled={{eq this.page 0}}>
              <ChevronLeft class="w-5" />
            </Button>
          </span>
          <span class="p-3">
            <Button
              {{on "click" this.nextPage}}
              @appearance="outlined"
              disabled={{eq this.lastViewPosition this.filteredResults.length}}
            >
              <ChevronRight class="w-5" />
            </Button>
          </span>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
