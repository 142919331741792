import { template as template_684207bb08bc42eab9bb02cfc4b2355b } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
const AppSidebarNavGroupsAdminEmployeesComponent: TOC<Signature> = template_684207bb08bc42eab9bb02cfc4b2355b(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.item
      @nestedRoute="admin.employees"
      @icon="person_search"
      @label={{t "sidebar.employees"}}
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsAdminEmployeesComponent;
