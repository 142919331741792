import { template as template_ab53935c77824b0692eaa94ec187fe3e } from "@ember/template-compiler";
import { action } from '@ember/object';
import { eq, or } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppSidebarAdminNav from './sidebar/admin-nav';
import AppSidebarEmployeeNav from './sidebar/employee-nav';
import AppSidebarFamilyNav from './sidebar/family-nav';
import AppSidebarOwnerNav from './sidebar/owner-nav';
import AppSidebarPslfAdminNav from './sidebar/pslf-admin-nav';
import AppSidebarTasApproverNav from './sidebar/tas-approver-nav';
import Component from '@glimmer/component';
import screenIs from 'tio-common/helpers/screen-is';
import TioDialogDocumentAsset from 'tio-common/components/tio/dialog/document-asset';
import TioSidebar from 'tio-common/components/tio/sidebar';
import type DataService from 'tio-employee/services/data';
import type EmployeeService from 'tio-employee/services/employee';
import type PartnerService from 'tio-employee/services/partner';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type SessionContextService from 'tio-employee/services/session-context';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type ZendeskService from 'tio-employee/services/zendesk';
import UiSidebarNav from 'tio-common/components/ui/sidebar/nav';
const userNavs = {
    ACCOUNT_OWNER: 'owner',
    EMPLOYEE_ADMIN: 'admin',
    FAMILY_MEMBER: 'family',
    PARTICIPANT: 'employee',
    PSLF_ADMIN: 'pslfAdmin',
    TAS_APPROVER: 'tasApprover',
    TIO_ACCOUNT_MANAGER: 'admin',
    TIO_ADMIN: 'admin'
};
export interface SidebarSignature {
    Element: HTMLElement;
}
export default class AppSidebar extends Component<SidebarSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    data: DataService;
    @service
    zendesk: ZendeskService;
    @service
    sideDrawer: SideDrawerService;
    @service
    preRegister: PreRegisterService;
    @service
    partner: PartnerService;
    @service
    employee: EmployeeService;
    @tracked
    showingPrivacyPolicy = false;
    @tracked
    showingTermsOfUse = false;
    get support() {
        return this.partner.companySettings?.modules?.support;
    }
    get isFaqsEnabled() {
        return this.partner.isFaqEnabled;
    }
    get showFaq() {
        return (!this.partner.isTaOnly || !this.employee.isTaOnlyEmployee) && this.isFaqsEnabled;
    }
    get termsOfUse() {
        return this.preRegister.termsOfUse;
    }
    get privacyPolicy() {
        return this.preRegister.privacyPolicy;
    }
    get userNav() {
        if (this.sessionContext.isFamilyMember) {
            return userNavs['FAMILY_MEMBER'];
        }
        if (this.sessionContext.actualCurrentRelationshipType?.startsWith('TAS.Approver') || this.sessionContext.actualCurrentRelationshipType === 'TAS.Admin.SUPERUSER') {
            return userNavs['TAS_APPROVER'];
        }
        // @ts-expect-error not sure
        return userNavs[this.sessionContext.actualCurrentRelationshipType] ?? 'employee';
    }
    @action
    openDialog(name: string, onClose: () => void) {
        const member = 'showing' + name.charAt(0).toUpperCase() + name.substring(1);
        onClose();
        // NOTE: Delay the opening to give time for the page to reflow after the
        //       sidebar closes. This reduces an issue on mobile where the layout
        //       width is wider than the viewport width, which causes the dialog to
        //       be incorrectly sized. If we switch to a sidebar on mobile that
        //       slides out over the page (so doesn't cause a reflow), `setTimeout`
        //       can be removed. [twl 7.Oct.22]
        setTimeout(()=>{
            //@ts-expect-error - reading 'member' as a string
            this[member] = true;
        }, 100);
    }
    @action
    openTechSupport() {
        this.zendesk.openWebChatWindow();
    }
    @action
    closeShowingPrivacyPolicy() {
        this.showingPrivacyPolicy = false;
    }
    @action
    closeShowingTermsOfUse() {
        this.showingTermsOfUse = false;
    }
    static{
        template_ab53935c77824b0692eaa94ec187fe3e(`
    <TioSidebar
      @initialState="closed"
      @collapsedWidth={{if (screenIs "md") "4.275rem" "0"}}
      class="fixed h-full
        {{if (screenIs 'md') '' 'overflow-x-hidden'}}
        {{if this.sideDrawer.isOpen 'overflow-y-auto'}}"
    >
      <:content as |props|>
        <UiSidebarNav
          @parentRoute="authenticated"
          @sidebarWidth={{if props.isOpen "full" "icon"}}
          @itemStyle="font-semibold text-lg text-white h-14 px-5 py-3 hover:bg-violet-highlight"
          @itemActiveStyle="bg-fuscia-300 hover:bg-fuscia-300"
          @groupItemStyle="text-white text-base pr-5 py-3 hover:bg-violet-highlight"
          @groupItemActiveStyle="font-semibold"
          @popoutContainerStyle="-mt-14"
          @popoutGroupHeaderStyle="hover:bg-inherit cursor-default font-semibold text-lg text-white h-14 px-5 py-3 border-b border-violet-highlight"
          @popoutGroupHeaderActiveStyle="bg-fuscia-300 border-fuscia-300 hover:bg-fuscia-300"
          @noIconStyle={{if props.isOpen "pl-14 ml-1" "pl-5"}}
          @onNavigate={{props.closeDrawer}}
          as |nav|
        >
          {{#if (eq this.userNav "admin")}}
            <AppSidebarAdminNav @nav={{nav}} />
          {{else if (eq this.userNav "owner")}}
            <AppSidebarOwnerNav @nav={{nav}} />
          {{else if (eq this.userNav "pslfAdmin")}}
            <AppSidebarPslfAdminNav @nav={{nav}} />
          {{else if (eq this.userNav "tasApprover")}}
            <AppSidebarTasApproverNav @nav={{nav}} />
          {{else if (eq this.userNav "employee")}}
            {{#if this.employee.isLoadedEmployee}}
              {{! @glint-expect-error: type mismatch}}
              <AppSidebarEmployeeNav @nav={{nav}} />
            {{/if}}
          {{else if (eq this.userNav "family")}}
            {{! @glint-expect-error: type mismatch}}
            <AppSidebarFamilyNav @nav={{nav}} />
          {{else}}
            <p class="px-12">{{t "sidebar.unknown_user_menu_type"}}</p>
          {{/if}}

          <nav.divider />
          <nav.item
            @route="authenticated.contact-us"
            @icon="call"
            @label="Contact Us"
            @sidebarWidthTransition="opacity:0%,100%"
          />
          {{#if this.showFaq}}
            <nav.item
              @href="https://tio.zendesk.com/hc/en-us/categories/360000194112-FAQ"
              @label="FAQ"
              @icon="help"
              @sidebarWidthTransition="opacity:0%,100%"
            />
          {{/if}}
          {{#if props.isOpen}}
            <nav.divider />
            <nav.group @sidebarWidthTransition="opacity:0%,100%" as |group|>
              <group.item @href="https://www.tuition.io/about-us/" @label="About Us" />

              <group.item @href="https://www.tuition.io/blog/" @label="Blog" />

              <group.item @href="https://www.tuition.io/press-releases/" @label="Press" />

              <group.item
                @label="Terms of Use"
                {{on "click" (fn this.openDialog "termsOfUse" props.closeDrawer)}}
              />

              <group.item
                @label="Privacy Policy"
                {{on "click" (fn this.openDialog "privacyPolicy" props.closeDrawer)}}
              />

              <nav.divider />

              <div class="text-xs pt-2 px-4 pb-2 whitespace-nowrap">
                {{t "sidebar.powered_by"}}
                <span class="font-bold">
                  {{t "tuition_io_lowercase"}}
                </span>
              </div>
            </nav.group>
          {{/if}}
        </UiSidebarNav>

        {{! Dialogs must exist outside 'Ui::Sidebar::Nav' to prevent double
          rendering from sidebar and popout menus. [twl 6.Oct.22] }}
        <TioDialogDocumentAsset
          @title="Privacy Policy"
          @assetName={{or this.privacyPolicy.staticJsonAssetName "privacy_policy"}}
          @show={{this.showingPrivacyPolicy}}
          @onCancel={{this.closeShowingPrivacyPolicy}}
        />

        <TioDialogDocumentAsset
          @title="Terms of Service"
          @assetName={{or this.termsOfUse.staticJsonAssetName "terms_of_use"}}
          @show={{this.showingTermsOfUse}}
          @onCancel={{this.closeShowingTermsOfUse}}
        />

      </:content>
    </TioSidebar>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
