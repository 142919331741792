import { template as template_217061c3afc949b5821f0eba3bb937b5 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_217061c3afc949b5821f0eba3bb937b5(`
    {{! template-lint-disable no-bare-strings }}
    <h1>Start page for testing</h1>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
