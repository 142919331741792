import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import type SessionContextService from '../../../services/session-context';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';

export default class AuthenticatedObservabilityUploadRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;
  @service declare remoteEventLogger: RemoteEventLoggerService;

  queryParams = { source: { refreshModel: false } };

  async model(params: { source: string }) {
    const person = this.sessionContext.user.person;

    const institutionsQuery = {
      filter: {
        isActive: true,
        hasObservabilityInstructions: true,
      },
    };

    const financialInstitutionsResult = await this.store.query(
      'financial-institution',
      institutionsQuery
    );

    const financialInstitutions = [...financialInstitutionsResult].sort(
      (a: FinancialInstitutionModel, b: FinancialInstitutionModel) => a.name?.localeCompare(b.name)
    );

    this.remoteEventLogger.logObservabilityEvent({
      eventName: 'INITIALIZE',
      component: 'AuthenticatedObservabilityUploadRoute',
    });

    return { source: params.source, person, financialInstitutions };
  }
}
