import { template as template_5c74e0ef80314601912867cf9395d6b7 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { Divider } from 'tio-ui/components/utilities';
export default RouteTemplate(template_5c74e0ef80314601912867cf9395d6b7(`
    <div>
      {{! How Can We Help You? }}
      <div class="grid grid-col grid-cols-1 sm:grid-row sm:grid-cols-2">
        <div class="">
          <h1 class="text-3xl text-gray-600 font-light">
            {{t "askjeni.about.how_can_we_help_q"}}
          </h1>
          <div class="pt-4 text-gray-600 text-lg">
            {{t "askjeni.about.how_can_we_help_a"}}
          </div>

          {{! Anchor links }}
          <div class="pt-6 text-ocean-600 text-xl grid grid-col gap-y-4">
            <a href="#ask-our-coaches">
              {{t "askjeni.about.ask_our_coaches"}}
            </a>
            <a href="#get-support" class="pt-4">
              {{t "askjeni.about.get_support"}}
            </a>
            <a href="#previous-questions" class="pt-4">
              {{t "askjeni.about.previous_questions"}}
            </a>
            <a href="#learn-about-coaches" class="pt-4">
              {{t "askjeni.about.learn_about_coaches"}}
            </a>
          </div>

        </div>
        <div class="flex justify-self-end pt-8 sm:pt-0">
          {{svgJar
            "woman-on-laptop-working"
            width="100%"
            height="100%"
            role="img"
            desc=(t "svg.woman_on_laptop_working")
          }}
        </div>
      </div>
      <Divider class="my-16" />

      {{! Ask our student loan... }}
      <div class="grid grid-col grid-cols-1 align-content-middle sm:grid-row sm:grid-cols-2">
        <div class="">
          <div id="ask-our-coaches">
            <h1 class="text-3xl text-gray-600 font-light">
              {{t "askjeni.about.ask_our_coaches"}}
            </h1>
          </div>
          <div class="pt-4 text-gray-600 text-lg">
            {{t "askjeni.about.coaches_info_1"}}
          </div>
          <div class="pt-4 text-gray-600 text-lg">
            {{t "askjeni.about.coaches_info_2"}}
          </div>
        </div>
        <div class="grid sm:grid-cols-3 items-center w-full">
          <LinkTo
            @route="authenticated.repaying-student-debt.askjeni.schedule"
            class="pt-10 sm:pt-0 sm:col-start-2 sm:col-span-2 sm:pl-7 sm:border-l-8 border-ocean-600 text-ocean-600 sm:h-20 underline text-xl"
          >
            {{t "askjeni.about.ask_coaches_link_text"}}
          </LinkTo>
        </div>
      </div>
      <Divider class="my-16" />

      {{! Get program support }}
      {{! TODO: Provide links to chat }}
      <div class="grid grid-col grid-cols-1 sm:grid-row sm:grid-cols-2">
        <div class="">
          <div id="get-support">
            <h1 class="text-3xl text-gray-600 font-light">
              {{t "askjeni.about.get_support"}}
            </h1>
          </div>
          <div class="pt-4 text-gray-600 text-lg">
            {{t "askjeni.about.support_info_pt1"}}
            <LinkTo @route="authenticated.contact-us">
              {{t
                "askjeni.about.support_info_pt2"
                htmlSafe=true
                class="text-ocean-600 hover:text-ocean-800 underline font-semibold"
              }}
            </LinkTo>
            {{t "askjeni.about.support_info_pt3"}}
          </div>
        </div>
        <div class="grid flex sm:grid-cols-3 items-center w-full">
          <div
            class="pt-10 sm:pt-0 sm:col-start-2 sm:col-span-2 sm:pl-7 sm:border-l-8 border-ocean-600 text-ocean-600 sm:h-20 underline text-xl"
          >
            <LinkTo @route="authenticated.contact-us">
              {{t "askjeni.about.chat_link_text"}}
            </LinkTo>
          </div>
        </div>
      </div>
      <Divider class="my-16" />

      {{! See previously asked questions }}
      <div class="grid grid-col grid-cols-1 sm:grid-row sm:grid-cols-2">
        <div class="">
          <div id="previous-questions">
            <h1 class="text-3xl text-gray-600 font-light">
              {{t "askjeni.about.previous_questions"}}
            </h1>
          </div>
          <div class="pt-4 text-gray-600 text-lg">
            {{t "askjeni.about.questions_info"}}
          </div>
        </div>
        <div class="grid flex sm:grid-cols-3 items-center w-full">
          <a
            href="https://www.tuition.io/blog/"
            class="pt-10 sm:pt-0 sm:col-start-2 sm:col-span-2 sm:pl-7 sm:border-l-8 border-ocean-600 text-ocean-600 sm:h-20 underline text-xl"
          >
            {{t "askjeni.about.coach_blog_link_text"}}
          </a>
        </div>
      </div>
      <Divider class="my-16" />

      {{! Learn about our coaches }}
      <div>
        <div id="learn-about-coaches">
          <h1 class="text-3xl text-gray-600 font-light">
            {{t "askjeni.about.learn_about_coaches"}}
          </h1>
        </div>
        <div class="pt-4 text-gray-600 text-lg">
          {{t "askjeni.about.learn_coaches_info_1"}}
        </div>
        <div class="pt-4 text-gray-600 text-lg">
          {{t "askjeni.about.learn_coaches_info_2"}}
        </div>
        <div class="pt-4 text-gray-600 text-lg">
          {{t "askjeni.about.learn_coaches_info_3"}}
        </div>
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
