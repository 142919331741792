import { template as template_41c2b5a19852407e8ca30df3cba9fc7f } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
export interface S {
    Args: {
        model: TasProgramInstanceModel;
    };
}
export default RouteTemplate<S>(template_41c2b5a19852407e8ca30df3cba9fc7f(`
    {{pageTitle (t "tuition_assistance.preapproval_app.default")}}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      {{outlet}}
      <TuitionAssistanceProgramDetailsCancelRequest
        @application={{@model}}
        @modelTypes={{array "tas-program-instance"}}
        @canCancel={{true}}
        @linkText={{t "tuition_assistance.program_details.cancel_app.delete_program"}}
      />
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
