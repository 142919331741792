import { template as template_338cb4314730481b8e212c6d28377149 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { pslfEstimatorSchema, idrEstimatorSchema } from 'tio-common/validation-schema/forms/loan-forgiveness-estimator';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import findBy from 'ember-composable-helpers/helpers/find-by';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import inputmask from 'tio-common/modifiers/inputmask';
import MaterialIcon from 'tio-common/components/material-icon';
import tippy from 'ember-tippy/modifiers/tippy';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AnswerProfileService from 'tio-employee/services/answer-profile';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
type FormModel = {
    federalLoanTime: string;
    householdIncome: number;
    householdSize: number;
    academicDegree: string;
    madePayments: number;
    affordPayments: boolean;
};
export interface ForgivenessEstimatorFormSignature {
    Args: {
        loanSummary: LoanSummary;
        application: string;
        didSave: (recommendation: RecommendationModel) => void;
    };
    Element: HTMLElement;
}
class ForgivenessEstimatorFormComponent extends Component<ForgivenessEstimatorFormSignature> {
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @service
    answerProfile: AnswerProfileService;
    @tracked
    income?: {
        amount: string;
        send: number;
    };
    @tracked
    incomeToSend: unknown;
    @tracked
    familySize = 1;
    @tracked
    totalYears = 0;
    @tracked
    before2007 = false;
    @tracked
    degree = '';
    @tracked
    affordPayments = false;
    @tracked
    showCustomIncome = false;
    @tracked
    hasSubmitted = false;
    @tracked
    submitError = null;
    get loanSummariesByType() {
        return this.args.loanSummary?.summaryByLoanType || [];
    }
    get isPslf() {
        return this.args.application === 'PSLF';
    }
    get isIdr() {
        return this.args.application === 'IDR';
    }
    get dataStyle() {
        return this.isPslf ? 'PSLFv2' : 'IDR';
    }
    get validationSchema() {
        if (this.isPslf) {
            return pslfEstimatorSchema;
        }
        if (this.isIdr) {
            return idrEstimatorSchema;
        }
        return null;
    }
    get federalLoanTime() {
        if (this.before2007 === undefined) {
            return this.before2007;
        }
        return this.before2007 ? 'before-october-2007' : 'after-october-2007';
    }
    get madePayments() {
        const paymentsSum = this.totalYears * 12;
        return isNaN(paymentsSum) ? undefined : paymentsSum;
    }
    @action
    setIncome(value: {
        amount: string;
        send: number;
    }) {
        if (value?.amount === 'Custom') {
            this.showCustomIncome = true;
        } else {
            this.showCustomIncome = false;
            this.incomeToSend = value.send;
        }
        this.income = value;
    }
    @action
    setCustom(_value: unknown, event: Event) {
        // @ts-expect-error: inputmask is not a valid property on Event
        const unmaskedValue = event.target.inputmask.unmaskedvalue();
        this.incomeToSend = unmaskedValue * 100;
    }
    @action
    setTotalYears(selected: {
        value: number;
    }) {
        this.totalYears = selected?.value;
    }
    submitTask = dropTask(async ()=>{
        this.submitError = null;
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            return;
        }
        try {
            const saved = await this.saveFormValues.perform(this.formModel);
            this.hasSubmitted = false;
            await this.args.didSave?.(saved);
        } catch (e) {
            this.submitError = e;
            console.error(e);
        }
    });
    saveFormValues = dropTask(async (formModel)=>{
        const app = this.args.application;
        const calculationInput = this.formatPayload(formModel);
        const recommendation = await this.store.createRecord('recommendation', {
            user: this.sessionContext.user,
            calculationInput: {
                data: calculationInput
            },
            requestingApp: app
        });
        this.storeAnswersOnProfile(formModel);
        await recommendation.save();
        return recommendation;
    });
    storeAnswersOnProfile(formModel: unknown) {
        // We don't need this method to be awaited in the context of the estimator flow.
        // The answerProfile service will persist and finish this request, so as not
        // to interfere with the wait time or overall user experience of the estimator.
        try {
            const app = this.args.application;
            // @ts-expect-error: not sure
            this.answerProfile.storeAnswers(app, formModel);
        } catch (e) {
            // Catch and log but don't re-throw so as not to block estimator experience.
            console.error(e);
        }
    }
    formatPayload(formModel: FormModel) {
        const { dataStyle } = this;
        const { academicDegree, householdIncome, householdSize, madePayments, federalLoanTime, affordPayments } = formModel;
        return this.loanSummariesByType.map((loanData)=>{
            const { loanType, totalDisbursedAmount, repaymentPlan, /*federalLoanTime,*/ currentBalance, interestRate, monthlyPayment } = loanData;
            const formatted = {
                'data-style': dataStyle,
                'loan-type': {
                    'federal-loan-type': {
                        'loan-type': loanType,
                        'original-disbursement-amount': totalDisbursedAmount,
                        'repayment-plan': repaymentPlan,
                        'monthly-payment': monthlyPayment
                    },
                    // Check if this should be removed from form in favor of loan summary federalLoanTime data? - Julia 01/23/2023
                    'federal-loan-time': federalLoanTime
                },
                forgiveness: {
                    'made-payments': madePayments,
                    'academic-degree': academicDegree
                },
                'loan-details': {
                    'household-income': householdIncome,
                    'current-balance': currentBalance,
                    'interest-rate': interestRate,
                    'household-size': householdSize
                },
                purpose: {
                    'afford-payments': affordPayments
                }
            };
            return formatted;
        });
    }
    grossIncomes = [
        {
            amount: 'Less than 25K',
            send: 2500000
        },
        {
            amount: '25K - 30K',
            send: 2750000
        },
        {
            amount: '30K - 40K',
            send: 3500000
        },
        {
            amount: '40K - 50K',
            send: 4500000
        },
        {
            amount: '50K - 60K',
            send: 5500000
        },
        {
            amount: '60K - 70K',
            send: 6500000
        },
        {
            amount: '70K - 80K',
            send: 7500000
        },
        {
            amount: '80K - 100K',
            send: 9000000
        },
        {
            amount: '100K - 125K',
            send: 11250000
        },
        {
            amount: '125K - 150K',
            send: 13750000
        },
        {
            amount: '150K - 200K',
            send: 17500000
        },
        {
            amount: '200K - 250K',
            send: 22500000
        },
        {
            amount: '250K - 350K',
            send: 27500000
        },
        {
            amount: '350K - 500K',
            send: 42500000
        },
        {
            amount: 'More than 500K',
            send: 50000000
        },
        {
            amount: 'Custom',
            send: null
        }
    ];
    yearOptions = [
        {
            label: "I haven't started",
            value: 0
        },
        {
            label: '1 year',
            value: 1
        },
        {
            label: '2 years',
            value: 2
        },
        {
            label: '3 years',
            value: 3
        },
        {
            label: '4 years',
            value: 4
        },
        {
            label: '5 years',
            value: 5
        },
        {
            label: '6 years',
            value: 6
        },
        {
            label: '7 years',
            value: 7
        },
        {
            label: '8 years',
            value: 8
        },
        {
            label: '9 years',
            value: 9
        },
        {
            label: '10 years',
            value: 10
        }
    ];
    get formModel() {
        return {
            federalLoanTime: this.federalLoanTime,
            householdIncome: this.incomeToSend,
            householdSize: this.familySize,
            academicDegree: this.degree,
            madePayments: this.madePayments,
            affordPayments: this.affordPayments
        };
    }
    get formValidationErrors() {
        const schema = this.validationSchema;
        try {
            schema?.validateSync?.(this.formModel, {
                abortEarly: false
            });
        } catch (err) {
            return err.inner || [];
        }
        return [];
    }
    static{
        template_338cb4314730481b8e212c6d28377149(`
    <form ...attributes>
      {{! Household Income }}
      <div class="py-4">
        <label for="householdIncome" class="form-field-label">
          {{t "forgiveness_estimator.annual_income"}}
          <MaterialIcon
            @icon="info"
            class="mx-2 text-lg leading-5 align-bottom cursor-default text-ocean-600"
            {{tippy (t "forgiveness_estimator.annual_income_tooltip") placement="top"}}
          />
        </label>
        <FormSelect
          data-legacy-input
          name="householdIncome"
          @label=""
          {{! @glint-expect-error: issue with power select types}}
          @allowClear={{false}}
          @errors={{errorsForField "householdIncome" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @options={{this.grossIncomes}}
          @selected={{this.income}}
          @required="true"
          @onChange={{this.setIncome}}
          as |income|
        >
          {{! @glint-expect-error: issue with power select types}}
          {{income.amount}}
        </FormSelect>
      </div>

      {{! Custom Income Amount }}
      {{#if this.showCustomIncome}}
        <FormInput
          data-legacy-input
          id="custom-inc"
          @value="0"
          @onInput={{this.setCustom}}
          autocomplete="off"
          {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
        />
      {{/if}}

      {{! Household Size }}
      <FormInput
        data-legacy-input
        @label={{t "forgiveness_estimator.family_size"}}
        @containerClass="py-4"
        @hasSubmitted={{this.hasSubmitted}}
        @errors={{errorsForField "householdSize" schemaErrors=this.formValidationErrors}}
        @required="true"
        @value="{{this.familySize}}"
        @type="number"
        {{! @glint-expect-error: issue with power select types}}
        @onInput={{fn (mut this.familySize)}}
        autocomplete="off"
        min="1"
      />

      {{! Total Years Paid }}
      <div class="py-4">
        <label for="totalYears" class="form-field-label">
          {{t "forgiveness_estimator.how_many_years"}}
          {{#if this.isPslf}}
            <MaterialIcon
              @icon="info"
              class="mx-2 text-lg leading-5 align-bottom cursor-default text-ocean-600"
              {{tippy (t "forgiveness_estimator.how_many_years_tooltip") placement="top"}}
            />
          {{/if}}
        </label>
        <FormSelect
          data-legacy-input
          name="totalYears"
          @label=""
          {{! @glint-expect-error: issue with power select types}}
          @allowClear={{true}}
          @errors={{errorsForField "madePayments" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @options={{this.yearOptions}}
          @required="true"
          @selected={{findBy "value" this.totalYears this.yearOptions}}
          @onChange={{this.setTotalYears}}
          as |option|
        >
          {{! @glint-expect-error: issue with power select types}}
          {{option.label}}
        </FormSelect>
      </div>

      {{! Federal Loan Time }}
      <FormRadioGroup
        data-legacy-input
        @label={{t "forgiveness_estimator.before_2007"}}
        @containerClass="py-2"
        @hasSubmitted={{this.hasSubmitted}}
        @errors={{errorsForField "federalLoanTime" schemaErrors=this.formValidationErrors}}
        @required="true"
        @value={{this.before2007}}
        {{! @glint-expect-error: issue with power select types}}
        @onChange={{fn (mut this.before2007)}}
        as |Radio|
      >
        {{! @glint-expect-error: not sure}}
        <Radio @value={{true}} @label="Yes" />
        {{! @glint-expect-error: not sure}}
        <Radio @value={{false}} @label="No" />
      </FormRadioGroup>

      {{! Academic degree }}
      <FormRadioGroup
        data-legacy-input
        @label={{t "forgiveness_estimator.grad_or_undergrad"}}
        @containerClass="py-4"
        @errors={{errorsForField "academicDegree" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @required="true"
        @value={{this.degree}}
        {{! @glint-expect-error: issue with power select types}}
        @onChange={{fn (mut this.degree)}}
        as |Radio|
      >
        {{! @glint-expect-error: not sure}}
        <Radio
          @value="undergradOnly"
          @containerClass="accent-black"
          @label={{t "forgiveness_estimator.undergradOnly"}}
        />
        {{! @glint-expect-error: not sure}}
        <Radio @value="GradOnly" @label={{t "forgiveness_estimator.GradOnly"}} />
        {{! @glint-expect-error: not sure}}
        <Radio @value="undergradGrad" @label={{t "forgiveness_estimator.undergradGrad"}} />
      </FormRadioGroup>

      {{#if this.isIdr}}
        {{! Afford Payments }}
        <FormRadioGroup
          data-legacy-input
          @label="{{t 'forgiveness_estimator.afford_payments'}}"
          @containerClass="py-4"
          @hasSubmitted={{this.hasSubmitted}}
          @errors={{errorsForField "affordPayments" schemaErrors=this.formValidationErrors}}
          @required="true"
          @value={{this.affordPayments}}
          {{! @glint-expect-error: not sure}}
          @onChange={{fn (mut this.affordPayments)}}
          as |Radio|
        >
          {{! @glint-expect-error: not sure}}
          <Radio @value="yes" @label={{t "yes"}} />
          {{! @glint-expect-error: not sure}}
          <Radio @value="no" @label={{t "no"}} />
        </FormRadioGroup>
      {{/if}}

      <Button {{on "click" this.submitTask.perform}} @isRunning={{this.submitTask.isRunning}}>
        {{t "forgiveness_estimator.see_results"}}
      </Button>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ForgivenessEstimatorFormComponent;
