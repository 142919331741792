import { template as template_70b7215c61f04ed38de489b5742a9117 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { VStack, Header } from 'tio-ui/components/layout';
export default RouteTemplate(template_70b7215c61f04ed38de489b5742a9117(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
