import { template as template_c0cdec917a7a4ea7b41515da43a8ea87 } from "@ember/template-compiler";
import { A } from '@ember/array';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { not } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ConfirmNameModal from './confirm-name-modal';
import enrollFormSchema from 'tio-common/validation-schema/forms/enroll-form';
import ENV from 'tio-employee/config/environment';
import errorsForField from 'tio-common/helpers/errors-for-field';
import formatPhone from 'tio-common/helpers/format-phone';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import includes from 'ember-composable-helpers/helpers/includes';
import TioDialogDocumentAsset from 'tio-common/components/tio/dialog/document-asset';
import type { RegisterUser } from '../../../routes/enroll.ts';
import type ConditionModel from 'tio-common/models/condition';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type PreRegisterService from 'tio-employee/services/pre-register.ts';
import type RouterService from 'tio-employee/services/router.ts';
import type SessionService from 'tio-employee/services/session.ts';
import type StoreService from 'tio-common/services/store';
import type ZendeskService from 'tio-employee/services/zendesk.ts';
import { Button } from 'tio-ui/components/buttons';
import type Owner from '@ember/owner';
export interface AuthRegisterEnrollFormSignature {
    Args: {
        registerUser: RegisterUser | null;
        email?: string;
        passwordMinLength: number;
    };
}
export default class AuthRegisterEnrollFormComponent extends Component<AuthRegisterEnrollFormSignature> {
    @service
    store: StoreService;
    @service
    session: SessionService;
    @service
    router: RouterService;
    @service
    preRegister: PreRegisterService;
    @service
    zendesk: ZendeskService;
    @service
    notifications: NotificationsService;
    @tracked
    firstName = '';
    @tracked
    lastName = '';
    @tracked
    email = '';
    @tracked
    phone = '';
    @tracked
    password = '';
    @tracked
    passwordConfirmation = '';
    @tracked
    isAgreedTioTerms = false;
    @tracked
    persistName = false;
    @tracked
    isLoading = false;
    @tracked
    hasSubmitted = false;
    @tracked
    showingTermsOfUse = false;
    @tracked
    showingPrivacyPolicy = false;
    @tracked
    showingConfirmNameModal = false;
    @tracked
    employeeName = '';
    @tracked
    acceptedCustomTerms: number[] = A([]);
    constructor(owner: Owner, args: AuthRegisterEnrollFormSignature['Args']){
        super(owner, args);
        this.email = args.email || '';
        this.firstName = args.registerUser?.firstName || '';
        this.lastName = args.registerUser?.lastName || '';
        this.email = args.registerUser?.email || '';
    }
    get formModel() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phone,
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            isAgreed: this.isAgreed
        };
    }
    get formValidationErrors() {
        const schema = enrollFormSchema;
        const { passwordMinLength } = this.args;
        try {
            schema?.validateSync?.(this.formModel, {
                abortEarly: false,
                context: {
                    passwordMinLength
                }
            });
        } catch (err) {
            return err.inner || [];
        }
        return [];
    }
    get canSubmit() {
        return !this.formValidationErrors.length && this.isAgreed;
    }
    get termsOfUse() {
        return this.preRegister.termsOfUse;
    }
    get privacyPolicy() {
        return this.preRegister.privacyPolicy;
    }
    get customTerms() {
        return this.args.registerUser?.settings?.customTerms || this.preRegister.customTerms || [];
    }
    get didAcceptAllCustomTerms() {
        return this.acceptedCustomTerms.length === this.customTerms.length;
    }
    get isAgreed() {
        return this.isAgreedTioTerms && this.didAcceptAllCustomTerms;
    }
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
    enforceUSPhonePrefix(input: string): string {
        // Remove any non-numeric characters from the input
        const digits = input.replace(/\D/g, '');
        // Check if the cleaned number has 10 digits (add "1") or is an 11-digit starting with "1"
        if (digits.length === 10) {
            return `+1${digits}`;
        } else if (digits.length === 11 && digits.startsWith('1')) {
            return `+${digits}`;
        }
        // If the input is not 10 digits (after cleaning), or 11 digits starting with "1", it's invalid
        throw new Error('Invalid phone number. Expected 10 digits or 11 digits starting with "1".');
    }
    registerTask = dropTask(async ()=>{
        const query = location.search;
        const params = new URLSearchParams(query);
        const inviteCode = params.get('inviteCode');
        const { host } = this.store.adapterFor('application');
        this.hasSubmitted = true;
        const response = await fetch(`${host}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': ENV.apiKey
            },
            body: JSON.stringify({
                firstName: this.firstName,
                lastName: this.lastName,
                registrationEmail: this.email,
                employeeInviteCode: inviteCode,
                password: this.password,
                phoneNumber: this.phone.length ? this.enforceUSPhonePrefix(this.phone) : null,
                conditionsAccepted: [
                    this.termsOfUse?.id,
                    this.privacyPolicy?.id,
                    ...this.acceptedCustomTerms
                ].filter((id)=>!!id),
                persistName: this.persistName
            })
        });
        if (response.ok) {
            await this.session.authenticate('authenticator:database', this.email, this.password, {
                noTransitionAfterAuth: true
            });
            this.isLoading = false;
            this.hasSubmitted = false;
            if (this.session.isAuthenticated) {
                this.router.transitionTo('/dashboard');
            }
        } else {
            const res = await response.json();
            if ([
                'USER_FIRST_NAME_WRONG_EMPLOYEE',
                'USER_LAST_NAME_WRONG_EMPLOYEE'
            ].includes(res.error)) {
                this.showingConfirmNameModal = true;
                this.employeeName = res.full_name;
                return;
            }
            this.isLoading = false;
            this.hasSubmitted = false;
            this.notifications.add(res.error || 'An Error Occurred', {
                appearance: 'error'
            });
        }
    });
    @action
    setFirstName(value: string) {
        this.firstName = value;
    }
    @action
    setLastName(value: string) {
        this.lastName = value;
    }
    @action
    setEmail(value: string) {
        this.email = value;
    }
    @action
    setPassword(value: string) {
        this.password = value;
    }
    @action
    setPasswordConfirmation(value: string) {
        this.passwordConfirmation = value;
    }
    @action
    didChangeAcceptanceOfCustomTerm(condition: ConditionModel) {
        const index = this.acceptedCustomTerms.indexOf(condition.id);
        // Add or remove the condition ID from the list of accepted custom terms
        index === -1 ? (this.acceptedCustomTerms = [
            ...this.acceptedCustomTerms,
            condition.id
        ]) : (this.acceptedCustomTerms = this.acceptedCustomTerms.filter((id)=>id !== condition.id));
    }
    @action
    onPhoneChange(value: string, event: InputEvent) {
        // Remove any non-numeric characters from the input
        const target = event.target as HTMLInputElement;
        if (isNaN(Number(value))) {
            target.value = target.value.replace(/[^0-9]/g, '');
            value = target.value;
        }
        // Limit the input to 10 digits (or 11 if it starts with "1")
        if ((value.length > 10 && !value.startsWith('1')) || value.length > 11) {
            const length = value.startsWith('1') ? 11 : 10;
            target.value = target.value.slice(0, length);
            value = target.value;
        }
        this.phone = value;
    }
    @action
    showConditionsDialog(type: string, event: Event) {
        event.preventDefault();
        event.stopPropagation();
        switch(type){
            case 'terms':
                this.showingTermsOfUse = true;
                break;
            case 'privacy':
                this.showingPrivacyPolicy = true;
                break;
        }
    }
    @action
    setIsAgreedTioTerms(value: boolean) {
        this.isAgreedTioTerms = value;
    }
    @action
    onCloseConfirmNameModal() {
        this.isLoading = false;
        this.showingConfirmNameModal = false;
        this.employeeName = '';
        this.zendesk.openWebChatWindow();
    }
    @action
    submitWithPersistedName() {
        this.isLoading = true;
        this.showingConfirmNameModal = false;
        this.employeeName = '';
        this.persistName = true;
        this.registerTask.perform();
    }
    @action
    submit() {
        this.isLoading = true;
        this.registerTask.perform();
    }
    static{
        template_c0cdec917a7a4ea7b41515da43a8ea87(`
    <form>
      <div class="grid sm:grid-flow-row sm:grid-cols-2 gap-4 mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.first_name"}}
          @value={{this.firstName}}
          @hint={{t "login.required"}}
          @onInput={{this.setFirstName}}
          @errors={{errorsForField "firstName" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @containerClass="content-start"
          data-test-first-name
        />
        <FormInput
          data-legacy-input
          @label={{t "login.last_name"}}
          @value={{this.lastName}}
          @hint={{t "login.required"}}
          @onInput={{this.setLastName}}
          @errors={{errorsForField "lastName" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @containerClass="content-start"
          data-test-last-name
        />
      </div>
      <div class="grid sm:grid-flow-row sm:grid-cols-2 gap-4 mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.email"}}
          @value={{this.email}}
          @hint={{t "login.email_hint"}}
          @onInput={{this.setEmail}}
          @type="email"
          @errors={{errorsForField "email" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          {{! HACK: I had to add the break points in order to keep the inputs aligned when they are in a row }}
          {{! The reason this is an issue is because the hints are different sizes and that + the error message throws off the alignment }}
          @containerClass="md:h-40"
          class="md:mt-3 md:h-12"
          data-test-email
        />
        <FormInput
          data-legacy-input
          @label={{t "login.mobile"}}
          @value={{formatPhone this.phone}}
          @hint={{t "login.mobile_hint"}}
          @onChange={{this.onPhoneChange}}
          @errors={{errorsForField "phoneNumber" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          {{! HACK: I had to add the break points in order to keep the inputs aligned when they are in a row }}
          {{! The reason this is an issue is because the hints are different sizes and that + the error message throws off the alignment }}
          @containerClass="md:h-40"
          class="md:mt-12 md:h-12"
          type="tel"
          data-test-phone
        />
      </div>
      <div class="grid grid-flow-cols gap-4 mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.create_password"}}
          @hint={{t "login.minimum_characters" min=@passwordMinLength}}
          @value={{this.password}}
          @errors={{errorsForField "password" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @onInput={{this.setPassword}}
          @type="password"
          data-test-password
        />

        <FormInput
          data-legacy-input
          @label={{t "login.reenter_password"}}
          @hint={{t "login.minimum_characters" min=@passwordMinLength}}
          @value={{this.passwordConfirmation}}
          @errors={{errorsForField "passwordConfirmation" schemaErrors=this.formValidationErrors}}
          @onInput={{this.setPasswordConfirmation}}
          @type="password"
          data-test-password-confirmation
        />
      </div>

      <FormCheckbox
        data-legacy-input
        @checked={{this.isAgreedTioTerms}}
        @onChange={{this.setIsAgreedTioTerms}}
        aria-labelledby="appTermsAndPrivacy"
      >
        <div id="appTermsAndPrivacy" class="ml-1 break-words w-full max-w-xl pr-5">
          {{t "login.i_have_read_and_agree_to_tuitions"}}
          <button
            class="tio-copy"
            type="button"
            {{on "click" (fn this.showConditionsDialog "terms")}}
          >
            {{t "login.terms_of_service"}}
          </button>
          {{t "and"}}
          <button
            class="tio-copy"
            type="button"
            {{on "click" (fn this.showConditionsDialog "privacy")}}
          >
            {{t "login.privacy_policy"}}.
          </button>
        </div>
      </FormCheckbox>

      {{#each this.customTerms as |condition|}}
        <div class="flex flex-row items-start my-2 w-full max-w-xl">
          <FormCheckbox
            data-legacy-input
            @size="lg"
            @checked={{includes condition this.acceptedCustomTerms}}
            @onChange={{fn this.didChangeAcceptanceOfCustomTerm condition}}
            aria-labelledby={{condition.id}}
          />
          <span id={{condition.id}} class="ml-1 w-full max-w-xl break-words pr-5">
            {{htmlSafe condition.fullText}}
          </span>
        </div>
      {{/each}}

      <div class="flex flex-col items-center mt-5">
        <Button
          @intent="primary"
          {{on "click" this.submit}}
          disabled={{not this.canSubmit}}
          @isRunning={{this.isLoading}}
          class="w-36 mb-4"
        >
          {{t "login.get_started"}}
        </Button>
      </div>
    </form>

    <ConfirmNameModal
      @show={{this.showingConfirmNameModal}}
      @onClose={{this.onCloseConfirmNameModal}}
      @employeeFullName={{this.employeeName}}
      @inputName={{this.fullName}}
      @onSubmit={{this.submitWithPersistedName}}
    />

    <TioDialogDocumentAsset
      @title="Privacy Policy"
      {{! @glint-expect-error: should privacy be optional? }}
      @assetName={{this.privacyPolicy.staticJsonAssetName}}
      @show={{this.showingPrivacyPolicy}}
      @onCancel={{fn (mut this.showingPrivacyPolicy) false}}
    />

    <TioDialogDocumentAsset
      @title="Terms of Service"
      {{! @glint-expect-error: should privacy be optional? }}
      @assetName={{this.termsOfUse.staticJsonAssetName}}
      @show={{this.showingTermsOfUse}}
      @onCancel={{fn (mut this.showingTermsOfUse) false}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
