import { template as template_07675ea150c5443188cde7209cddbc46 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsPayingForCollegeComponent: TOC = template_07675ea150c5443188cde7209cddbc46(`
  <LinkTo @route="authenticated.questions-you-have.paying-for-college">
    <Tile @headerText={{t "dashboard_tiles.types_of_student_loans"}}>
      <:description>
        <span>{{t "dashboard_tiles.learn_how_federal_loans"}}</span>
        <p class="font-semibold my-4 text-xl">
          {{t "dashboard_tiles.what_is_a_fed_loan"}}
        </p>
        <span>{{t "dashboard_tiles.fed_loan_blurb"}}</span>
      </:description>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsPayingForCollegeComponent;
