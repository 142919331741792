import { attr, belongsTo, hasMany } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type FinancialInstitutionModel from './financial-institution.ts';
import type PersonModel from './person.ts';
import type ObservableAssetModel from './observable-asset';
import type ObservableDocumentLogModel from './observable-document-log';
import type AccountTransactionModel from './account-transaction';

const MODEL_NAME = 'observable-document';
export const EXTRACTION_STATES = {
  'ExtractionState.UPLOADED': 1, // probably won't ever see this state
  'ExtractionState.DISPATCHED': 2,
  'ExtractionState.PROCESSING': 3,
  'ExtractionState.INVALID': 3,
  'ExtractionState.FAILED': 3,
  'ExtractionState.PROCESSED': 4,
  'ExtractionState.DERELICT': 4,
};

export const SUBSISTENCE_STATES = [
  'SubsistenceState.IMPLICIT',
  'SubsistenceState.IN_DISPUTE',
  'SubsistenceState.EXPLICIT',
  'SubsistenceState.REJECTED',
];

export const REFLECTION_STATES = [
  'RefplectionState.NOT_APPLIED',
  'RefplectionState.IN_PROGRESS',
  'RefplectionState.APPLIED',
  'ReflectionState.PARTIALLY_APPLIED',
];

export const OBSERVABLE_DOCUMENT_PROVIDERS = [
  'ObservableProvider.ACCOUNT_STATEMENT',
  'ObservableProvider.NSLDS',
  'ObservableProvider.ACCOUNT_TXN_HISTORY',
  'ObservableProvider.AMBIGUOUS',
];

export type AccountStatementStructuredContent = {
  account_number?: string;
  principal_balance_amount?: number;
  servicer_name?: string;
  filename: string;
  loan_details: {
    [key: string]: {
      loan_name?: string;
      loan_type?: string;
      loan_current_principal_balance: string;
      loan_disbursement_date: string;
    };
  };
};

export type NsldsStructuredContent = {
  filename: string;
  file: {
    file_request_date: string;
  };
  student: {
    student_first_name: string;
    student_middle_initial: string;
    student_last_name: string;
    student_total_all_grants: string;
    student_total_all_loans_outstanding_principal: string;
    student_total_all_loans_outstanding_interest: string;
  };
  loans: {
    loan_type_description: string;
    loan_type_code: string;
    loan_outstanding_principal_balance: string;
    loan_date: string;
    current_loan_status_description: string;
    loan_outstanding_interest_balance: string;
  }[];
};

export default class ObservableDocumentModel extends DigitalAsset {
  /*************************
   **  Attributes         **
   *************************/
  // unique identifier associated with a batch of documents that were uploaded together
  @attr declare batch: string;
  @attr declare via: 'EMAIL' | 'UPLOAD';
  @attr declare contentType: 'application/pdf' | 'application/txt';
  @attr declare provider: string;
  // serialized data parsed from asset
  // TODO: narrow this type when extracted content is more defined - James 20241001
  /*
    servicer_name?: string;
    current_principal_balance?: number;
    account_number?: string;
    loan_details: {
      [key: string]: {
        loan_current_principal_balance: number;
      }
    }
  */
  @attr declare extractedContent: Record<string, unknown>;
  @attr declare structuredContent: AccountStatementStructuredContent | NsldsStructuredContent;
  @attr declare providedAt: string;
  @attr declare extractionState: keyof typeof EXTRACTION_STATES;
  @attr declare subsistenceState: (typeof SUBSISTENCE_STATES)[number];
  @attr declare reflectionState: (typeof REFLECTION_STATES)[number];
  @attr declare createdAt: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('observable-asset', { async: false, inverse: 'observableDocument' })
  declare observableAsset: ObservableAssetModel;

  @belongsTo('person', { async: false, inverse: 'observableDocuments' })
  declare person: PersonModel;

  @belongsTo('financial-institution', { async: false, inverse: null })
  declare financialInstitution: FinancialInstitutionModel;

  @hasMany('account-transaction', { async: false, inverse: 'observableDocument' })
  declare accountTransactions: AccountTransactionModel[];

  @hasMany('observable-document-log', { async: false, inverse: 'observableDocument' })
  declare observableDocumentLogs: ObservableDocumentLogModel[];

  async dispute(note: string) {
    return await this.store.adapterFor(MODEL_NAME).dispute(this, note);
  }

  /*************************
   **  Computed           **
   *************************/

  get nsldsSummary() {
    if (this.provider === 'ObservableProvider.NSLDS') {
      const content = this.structuredContent as NsldsStructuredContent;
      return {
        fileRequestDate: content.file.file_request_date,
        student: {
          firstName: content.student.student_first_name,
          middleInitial: content.student.student_middle_initial,
          lastName: content.student.student_last_name,
          totalAllGrants: parseFloat(content.student.student_total_all_grants),
          totalAllLoansOutstandingPrincipal: parseFloat(
            content.student.student_total_all_loans_outstanding_principal
          ),
          totalAllLoansOutstandingInterest: parseFloat(
            content.student.student_total_all_loans_outstanding_interest
          ),
        },
        loanTotal: content.loans.length,
      };
    }
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    observableDocument: ObservableDocumentModel;
  }
}
