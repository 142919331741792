import { template as template_19afb3666f654274aa52258190f164ba } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FileUploader from 'tio-common/components/file-uploader';
import FormCheckboxGroup from '@frontile/forms-legacy/components/form-checkbox-group';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import FormTextarea from '@frontile/forms-legacy/components/form-textarea';
import MaterialIcon from 'tio-common/components/material-icon';
import TioAlert from 'tio-common/components/tio/alert';
import type { IntlService } from 'ember-intl';
import type DigitalAssetModel from 'tio-common/models/digital-asset';
import type DigitalAssetsService from 'tio-common/services/digital-assets';
import type EmployeeModel from 'tio-common/models/employee';
import type PslfAssetModel from 'tio-common/models/pslf-asset';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import type StoreService from 'tio-common/services/store';
import type Owner from '@ember/owner';
export interface PslfUploadModalSignature {
    Args: {
        employee: EmployeeModel;
        profile: PslfProfileModel;
        didSave?: () => void;
        onCancel?: () => void;
    };
    Element: HTMLDivElement;
}
type FormType = {
    text: string;
    value: string;
};
export default class PslfUploadModal extends Component<PslfUploadModalSignature> {
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @service
    digitalAssets: DigitalAssetsService;
    @tracked
    formType: FormType;
    @tracked
    details?: DigitalAssetModel['details'];
    @tracked
    description = '';
    @tracked
    didConfirmWarning = false;
    @tracked
    didConfirmWarning2 = false;
    @tracked
    didConfirmWarning3 = false;
    @tracked
    isFileAttached = false;
    @tracked
    validationErrors?: string[];
    error?: string;
    docTypes: FormType[];
    constructor(owner: Owner, args: PslfUploadModalSignature['Args']){
        super(owner, args);
        this.docTypes = [
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_or_ecf_form'),
                value: 'pslf_or_ecf_form'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_letter'),
                value: 'pslf_letter'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_loan'),
                value: 'pslf_loan'
            },
            {
                text: this.intl.t('pslf.document_repo.classification.pslf_other'),
                value: 'pslf_other'
            }
        ];
    }
    get canSubmit() {
        return this.isFileAttached && this.hasAcknowledgedDisclaimer;
    }
    get isPslfEcfForm() {
        return this.formType?.value === 'pslf_or_ecf_form';
    }
    get hasAcknowledgedDisclaimer() {
        if (this.isPslfEcfForm) {
            return this.didConfirmWarning && this.didConfirmWarning2 && this.didConfirmWarning3;
        }
        return true;
    }
    @action
    setFormType(value: FormType) {
        this.formType = value;
    }
    @action
    async didUploadFile(details: DigitalAssetModel['details']) {
        this.details = details;
        this.isFileAttached = true;
    }
    saveAsset = dropTask(async ()=>{
        const asset: PslfAssetModel = this.digitalAssets.createAssetFromS3Details('pslfAsset', this.details);
        asset.description = this.description;
        asset.classification = this.formType.value;
        const { employee, profile } = this.args;
        asset.employees.push(employee);
        asset.pslfProfiles.push(profile);
        const saved: PslfAssetModel = await asset.save();
        await this.store.findRecord('pslfProfile', profile.id, {
            include: 'attachments',
            reload: true
        });
        this.args.didSave?.();
        return saved;
    });
    @action
    cancel() {
        this.args.onCancel?.();
        delete this.error;
    }
    static{
        template_19afb3666f654274aa52258190f164ba(`
    <Modal
      @isOpen={{true}}
      @onClose={{@onCancel}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "pslf.document_repo.send_us_your_doc"}}</m.Header>
      <m.Body>
        {{#if this.isPslfEcfForm}}
          <TioAlert
            @type="warning"
            @allowDismiss={{false}}
            @iconClass="sm:!self-start"
            class="max-w-screen-lg mb-8"
          >
            <:body>
              <p class="mb-2 font-semibold">{{t "pslf.document_repo.pslf_form_type_disclaimer"}}</p>
              <div class="text-sm ml-4 mb-3">
                <span>
                  {{t "mail_to"}}
                  <br />
                  {{t "pslf.document_repo.dept_ed_mailing_address" htmlSafe=true}}
                  <br />
                  <br />
                  {{t "fax_to"}}
                  {{t "pslf.document_repo.dept_ed_fax_no"}}
                </span>
              </div>
              <p class="font-bold my-2">
                {{t "pslf.document_repo.disclaimer_header"}}
              </p>
              <FormCheckboxGroup
                data-legacy-input
                class="my-2"
                @errors={{this.validationErrors}}
                as |Checkbox|
              >
                <Checkbox
                  @name="notDeptEd"
                  @label={{t "pslf.document_repo.not_dept_ed"}}
                  @checked={{this.didConfirmWarning}}
                  @onChange={{fn (mut this.didConfirmWarning)}}
                  @containerClass="mb-1"
                />
                <Checkbox
                  @name="mustMail"
                  @label={{t "pslf.document_repo.must_mail"}}
                  @checked={{this.didConfirmWarning2}}
                  @onChange={{fn (mut this.didConfirmWarning2)}}
                  @containerClass="mb-1"
                />
                <Checkbox
                  @name="tioDoesNotSend"
                  @label={{t "pslf.document_repo.tio_does_not_submit"}}
                  @checked={{this.didConfirmWarning3}}
                  @onChange={{fn (mut this.didConfirmWarning3)}}
                  @containerClass="mb-1"
                />
              </FormCheckboxGroup>
            </:body>
          </TioAlert>
        {{/if}}
        <div class="grid gap-8 w-full max-w-screen-lg sm:grid-cols-1 md:grid-cols-2">
          <div>
            <div class="mb-2">
              <p class="mb-4">{{t "pslf.document_repo.why_upload_docs"}}</p>
              <p class="text-lg font-semibold mb-4 text-gray-600">
                {{t "pslf.document_repo.what_type_of_doc"}}
              </p>
              <FormSelect
                data-legacy-input
                {{! @glint-expect-error: bug in power select}}
                @renderInPlace={{false}}
                @allowClear={{true}}
                @label={{t "pslf.document_repo.document_type"}}
                @searchEnabled={{true}}
                @searchField="name"
                @options={{this.docTypes}}
                @selected={{this.formType}}
                @onChange={{this.setFormType}}
                @hint={{t "pslf.document_repo.required"}}
                as |formType|
              >
                {{! @glint-expect-error: bug in power select}}
                {{formType.text}}
              </FormSelect>
            </div>
            <div class="mb-2">
              <FormTextarea
                data-legacy-input
                @label={{t "pslf.document_repo.text_area_label"}}
                @value={{this.description}}
                @onInput={{fn (mut this.description)}}
              />
            </div>
          </div>
          <div>
            <p>{{t "upload_requirements"}}</p>
            {{#if this.isFileAttached}}
              <div
                class="p-2 border border-gray-300 border-dashed h-full flex flex-col items-center justify-center min-h-56"
              >
                <MaterialIcon @icon="description" class="text-gray-500" />
                <p>{{this.details.filename}}</p>
              </div>
            {{else}}
              <FileUploader class="min-h-56 h-full" @didUploadFile={{this.didUploadFile}} />
            {{/if}}
          </div>
        </div>

      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.saveAsset.isRunning}}
          @onSubmit={{this.saveAsset.perform}}
          @submitDisabled={{not this.canSubmit}}
          @onCancel={{this.cancel}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
