import { template as template_40fa1dbbc0a0478c87c6a9becf343969 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfPaymentsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfPaymentsRouteSignature>(template_40fa1dbbc0a0478c87c6a9becf343969(`{{outlet}}`, {
    eval () {
        return eval(arguments[0]);
    }
}));
