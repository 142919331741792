import { template as template_bf99a6147aec4f538d4994eb3dc04eaa } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type SessionContext from 'tio-employee/services/session-context';
import TasPayablesReport from 'tio-common/components/reporting/tas/payables';
import { VStack } from 'tio-ui/components/layout';
class ReportingTasPayablesRouteComponent extends Component {
    @service
    sessionContext: SessionContext;
    static{
        template_bf99a6147aec4f538d4994eb3dc04eaa(`
    <VStack>
      <TasPayablesReport
        @returnRoute="authenticated.admin.reporting.index"
        @sessionContext={{this.sessionContext}}
      />
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingTasPayablesRouteComponent);
