import { template as template_2b13f7face664b3b9a57f4debf2bd5ca } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { getObservableProductRoute } from 'tio-employee/services/observability';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { Textarea } from 'tio-ui/components/forms';
import { tracked } from '@glimmer/tracking';
import AccountSummary from 'tio-employee/components/observability/account-summary';
import Alert from 'tio-ui/components/alert';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import Component from '@glimmer/component';
import Document from 'ember-static-heroicons/components/outline-24/document';
import RouteTemplate from 'ember-route-template';
import type { ObservableSource } from 'tio-employee/services/observability';
import type IntlService from 'ember-intl/services/intl';
import type ObservabilityService from 'tio-employee/services/observability';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
interface ObservabilityDisputeRouteSignature {
    Args: {
        model: {
            source: ObservableSource;
            observableDocument: ObservableDocumentModel;
        };
    };
    Element: HTMLDivElement;
}
class ObservabilityDisputeRoute extends Component<ObservabilityDisputeRouteSignature> {
    @service
    intl: IntlService;
    @service
    observability: ObservabilityService;
    @service
    remoteEventLogger: RemoteEventLoggerService;
    @tracked
    note?: string;
    @tracked
    loading = false;
    @tracked
    submitted = false;
    @tracked
    submissionError: Error;
    get documentCreated() {
        const { observableDocument } = this.args.model;
        const createdAtDate = new Date(observableDocument.createdAt);
        const date = createdAtDate.toLocaleDateString();
        const time = createdAtDate.toLocaleTimeString();
        const via = this.intl.t(`observability.${observableDocument.via}`);
        return {
            via,
            date,
            time
        };
    }
    get submitDisabled() {
        if ((!this.note && !this.submissionError) || this.loading || this.submitted) {
            return true;
        }
        return false;
    }
    get document() {
        return this.args.model.observableDocument;
    }
    @action
    setNote(_value: string, e: Event) {
        this.note = (<HTMLTextAreaElement>e.target).value;
    }
    @action
    async submitDispute(e: Event) {
        e.preventDefault();
        const { observableDocument } = this.args.model;
        this.loading = true;
        try {
            // button is disabled if this.note is unset
            await observableDocument.dispute(this.note!);
            this.remoteEventLogger.logObservabilityEvent({
                eventName: 'PROCESSING_SUCCESS',
                component: 'ObservabilityService',
                document: observableDocument
            });
            this.submitted = true;
        } catch (e) {
            this.submissionError = e;
        } finally{
            this.loading = false;
        }
    }
    static{
        template_2b13f7face664b3b9a57f4debf2bd5ca(`
    <VStack>
      <Header>
        <LinkTo
          @route="authenticated.observability.confirm"
          @model={{@model.observableDocument.id}}
          class="align-bottom text-gray-900 font-medium"
        >
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
      </Header>
      <Section>
        <:header>{{t "observability.dispute.header"}}</:header>
        <:body>
          <article class="mb-4 text-tio-gray-600">
            <h1 class="tio-h4 mb-4">{{t "observability.dispute.document.header"}}</h1>
            <div class="flex items-center mb-2">
              <Document class="w-6 mr-2" />
              <span>{{@model.observableDocument.observableAsset.filename}}</span>
            </div>
            <div class="ml-6 italic">{{t
                "observability.dispute.document.uploaded"
                via=this.documentCreated.via
                date=this.documentCreated.date
                time=this.documentCreated.time
              }}</div>
          </article>
          <Alert @intent="warning" class="my-8">
            {{t "observability.dispute.review_alert"}}
          </Alert>
          <AccountSummary @document={{@model.observableDocument}} class="w-full md:w-2/3 my-8" />
          <p class="mb-4">{{t "observability.dispute.form.prompt"}}</p>
          <form {{on "submit" this.submitDispute}} class="pb-16">
            <label>
              {{t "observability.dispute.form.note"}}
              <Textarea @onInput={{this.setNote}} @value={{this.note}} class="sm:w-1/2" />
            </label>
            <div class="flex flex-wrap sm:flex-nowrap sm:w-1/2 gap-2">
              <Button
                type="submit"
                disabled={{this.submitDisabled}}
                @intent="primary"
                @isRunning={{this.loading}}
                class="w-full sm:shrink *:justify-center"
              >
                {{t "submit"}}
              </Button>
              <LinkTo
                @route="authenticated.observability.confirm"
                @model={{@model.observableDocument.id}}
                class="tio-btn tio-btn-default tio-btn-outlined w-full sm:shrink text-center"
              >
                {{t "cancel"}}
              </LinkTo>
            </div>
          </form>
        </:body>
      </Section>
    </VStack>
    <Modal @isOpen={{this.submitted}} @allowCloseButton={{false}} @size="xl" as |m|>
      <m.Body>
        <h1 class="tio-h2">{{t "observability.dispute.confirmed.header"}}</h1>
        <p class="my-8">{{t "observability.dispute.confirmed.message"}}</p>
        <LinkTo
          @route={{getObservableProductRoute @model.source}}
          class="tio-btn tio-btn-primary w-56 text-center"
        >
          {{t "ok"}}
        </LinkTo>
      </m.Body>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ObservabilityDisputeRoute);
