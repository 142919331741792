import { template as template_71c03035997c4e02ac8ae60c4e70e2d4 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn, hash } from '@ember/helper';
import { service } from '@ember/service';
import { t, type IntlService } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountRow from './account-row';
import Button from 'tio-common/components/tio/button';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import { Modal } from 'tio-ui/components/modal';
import type AccountModel from 'tio-common/models/account';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type Owner from '@ember/owner';
interface AccountDialogSignature {
    Args: {
        show: boolean;
        onClose: () => void;
        accountWithContribution: AccountModel;
        activeAccounts: AccountModel[];
        triggerElement?: HTMLElement | null;
        renderInPlace?: boolean;
    };
}
export default class AccountDialog extends Component<AccountDialogSignature> {
    @service
    notifications: NotificationsService;
    @service
    intl: IntlService;
    @tracked
    selectedAccount: AccountModel;
    constructor(owner: Owner, args: AccountDialogSignature['Args']){
        super(owner, args);
        this.selectedAccount = args.accountWithContribution;
    }
    get sortedAccounts() {
        return this.args.activeAccounts.sort((a, b)=>a.publicOrdinalValue - b.publicOrdinalValue);
    }
    get isNewAccountSelected() {
        return this.args.accountWithContribution !== this.selectedAccount;
    }
    @action
    onClickAccountRow(account: AccountModel) {
        this.selectedAccount = account;
    }
    @action
    onClose() {
        this.selectedAccount = this.args.accountWithContribution;
        this.args.onClose();
    }
    @action
    async changeAccountWithContribution(account: AccountModel) {
        this.args.activeAccounts.forEach((a)=>(a.publicOrdinalValue = 1));
        account.publicOrdinalValue = 0;
        this.args.activeAccounts.map(async (a)=>a.save());
        this.notifications.add(this.intl.t('slr.account_dialog.success'), {
            appearance: 'success'
        });
        this.onClose();
    }
    static{
        template_71c03035997c4e02ac8ae60c4e70e2d4(`
    <Modal
      @isOpen={{@show}}
      @onClose={{this.onClose}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      @renderInPlace={{@renderInPlace}}
      as |m|
    >
      <m.Header>{{t "slr.account_dialog.title"}}</m.Header>
      <m.Body>
        {{#each this.sortedAccounts as |account index|}}
          <AccountRow
            @account={{account}}
            @selectedAccount={{this.selectedAccount}}
            @index={{index}}
            @onClickAccountRow={{this.onClickAccountRow}}
            @sortedAccounts={{this.sortedAccounts}}
          />
        {{/each}}
        <TioClickableText
          @linkTo="authenticated.observability.upload"
          @query={{hash source="slr"}}
          @icon="play_arrow"
          @outlined={{true}}
          @iconClass="border-0 pl-0 -ml-2"
          @iconRight={{true}}
          @textClass="text-md underline text-ocean-600"
          class="w-fit"
        >
          {{t "slr.account_dialog.link_account"}}
        </TioClickableText>
      </m.Body>
      <m.Footer>
        {{#if this.isNewAccountSelected}}
          <div class="flex w-full mb-4 bg-gray-100 p-6 my-2">
            <MaterialIcon @icon="info" @style="outlined" class="text-gray-700" />
            <p>{{t "slr.account_dialog.change_account"}}</p>
          </div>
          <div class="flex justify-center space-x-2 w-full mt-4">
            <Button @outlined={{true}} class="sm:min-w-48 sm:w-fit" @onClick={{this.onClose}}>
              {{t "cancel"}}
            </Button>
            <Button
              class="sm:min-w-48 sm:w-fit"
              @onClick={{fn this.changeAccountWithContribution this.selectedAccount}}
            >
              {{t "done"}}
            </Button>
          </div>
        {{else}}
          <Button
            @outlined={{true}}
            @onClick={{this.onClose}}
            class="sm:min-w-48 sm:w-fit m-auto capitalize"
          >
            {{t "close"}}
          </Button>
        {{/if}}
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
