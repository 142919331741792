import { template as template_4d546df6c64f4150b7b2f02f09ee258f } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import Done from 'ember-static-heroicons/components/outline-24/check';
interface S {
    Element: HTMLElement;
}
const RepaymentOptionsConsolidationComponent: TOC<S> = template_4d546df6c64f4150b7b2f02f09ee258f(`
  <section class="tio-copy" ...attributes>
    <h2 class="text-2xl mb-6">{{t "wellness.what_is_consolidation"}}</h2>
    <p>{{t "wellness.consolidation_description"}}</p>
    <a href="#consolidation_vs_refinancing-paragraph" class="my-3 block">
      {{t "wellness.consolidation_vs_refinancing"}}
    </a>
    <a href="#benefits-paragraph" class="my-3 block">
      {{t "benefits"}}
    </a>
    <a href="#risks-paragraph" class="my-3 block">
      {{t "risks"}}
    </a>
    <a href="#eligibility-paragraph" class="my-3 block">
      {{t "eligibility"}}
    </a>
    <a href="#additional_consolidation-paragraph" class="my-3 block">
      {{t "wellness.consolidation_additional_considerations"}}
    </a>
    <a href="#refinancing-paragraph" class="my-3 block">
      {{t "wellness.consolidation_refinancing_alternative"}}
    </a>
  </section>

  <Divider class="my-9" />

  <section id="consolidation_vs_refinancing-paragraph">
    <h2 class="text-2xl my-6">{{t "wellness.consolidation_refinancing_or_consolidation"}}</h2>
    <table class="bg-gray-50">
      <thead class="bg-midnight text-white">
        <tr>
          {{! TODO: Ask about min-width }}
          <th class="w-44 p-6"></th>
          <th class="w-44 text-center">{{t "consolidation.default"}}</th>
          <th class="w-44 text-center">{{t "refinancing.default"}}</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr class="border-b">
          <td class="border-r p-4">{{t "consolidation.combine_loans"}}</td>
          <td>
            <Done class="text-midnight h-9 inline-block" />
          </td>
          <td class="border-l">
            <Done class="text-midnight h-9 inline-block" />
          </td>
        </tr>
        <tr class="border-b">
          <td class="border-r p-4">{{t "consolidation.lowers_rate"}}</td>
          <td></td>
          <td class="border-l">
            <Done class="text-midnight h-9 inline-block" />
          </td>
        </tr>
        <tr class="border-b">
          <td class="border-r p-4">{{t "consolidation.keeps_loans_eligible"}}</td>
          <td>
            <Done class="text-midnight h-9 inline-block" />
          </td>
          <td class="border-l"></td>
        </tr>
        <tr>
          <td class="border-r p-4">{{t "consolidation.changes_to_private"}}</td>
          <td></td>
          <td class="border-l">
            <Done class="text-midnight h-9 inline-block" />
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  <Divider class="my-9" />

  <section id="benefits-paragraph">
    <h3 class="text-2xl my-6">{{t "wellness.consolidation_what_benefits"}}</h3>
    <p class="mb-8">{{t "wellness.consolidation_what_benefits_description"}}</p>
    <p>{{t "wellness.consolidation_what_benefits_consolidation_not_decrease"}}</p>
  </section>

  <Divider class="my-9" />

  <section id="risks-paragraph">
    <h3 class="text-2xl my-6">{{t "wellness.consolidation_risks"}}</h3>
    <p>{{t "wellness.consolidation_risks_description"}}</p>
  </section>

  <Divider class="my-9" />

  <section id="eligibility-paragraph">
    <h3 class="text-2xl my-6">{{t "wellness.consolidation_which_eligible"}}</h3>
    <ul class="pl-4 mb-6 list-disc">
      {{#let
        (array
          (t "wellness.subsidized_consolidation_federal_stafford")
          (t "wellness.unsubsidized_consolidation_federal_stafford")
          (t "wellness.consolidation_plus_loans_FFEL")
          (t "wellness.consolidation_supplemental_loans")
          (t "wellness.consolidation_federal_perkins")
          (t "wellness.consolidation_nursing_student")
          (t "wellness.consolidation_nurse_faculty")
          (t "wellness.consolidation_health_education_assistance")
          (t "wellness.consolidation_health_professions")
          (t "wellness.consolidation_loans_for_disadantaged")
          (t "wellness.consolidation_direct_subsidized_loans")
          (t "wellness.consolidation_direct_unsubsidized_plus_loans")
          (t "wellness.consolidation_direct_unsubsidized_loans")
          (t "wellness.consolidation_FFEL_consolidation")
        )
        as |items|
      }}
        {{#each items as |item|}}
          <li class="py-1">{{item}}</li>
        {{/each}}
      {{/let}}
    </ul>
  </section>

  <Divider class="my-9" />

  <section id="additional_consolidation-paragraph">
    <h3 class="text-2xl my-6">{{t "wellness.consolidation_who_shouldnt"}}</h3>
    <p class="mb-8">
      <ol class="list-decimal pl-4">
        {{t "wellness.consolidation_who_shouldnt_description" htmlSafe=true}}
      </ol>
    </p>
  </section>

  <Divider class="my-9" />

  <section id="refinancing-paragraph" class="tio-copy">
    <h3 class="text-2xl my-6">
      {{t "wellness.consolidation_refinancing_good_alternative"}}
    </h3>
    <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
      <div class="col-span-2">
        <p>{{t "wellness.consolidation_refinancing_good_alternative_description"}}</p>
        <br />
        <p>{{t "wellness.consolidation_refinancing_good_alternative_description_keep_in_mind"}}</p>
      </div>
      <div class="border-l-4 border-midnight mt-4 ml-8 pl-4">
        <LinkTo
          @route="authenticated.questions-you-have.repayment-options.refinancing"
          class="text-2xl"
        >
          {{t "refinancing.learn_more"}}
        </LinkTo>
      </div>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepaymentOptionsConsolidationComponent;
