import { template as template_a0dd4388e1bc4742b2cde1accee940eb } from "@ember/template-compiler";
import { t } from 'ember-intl';
import MaterialIcon from 'tio-common/components/material-icon';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const FiveTwoNineBenefitsComponent: TOC = template_a0dd4388e1bc4742b2cde1accee940eb(`
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="flag" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.fed_tax_benefits_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.fed_tax_benefits_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="payments" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.state_tax_benefits_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.state_tax_benefits_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="tune" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.control_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.control_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="block" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.no_income_restrictions_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.no_income_restrictions_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="percent" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.minimal_financial_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.minimal_financial_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="insights" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.high_contribution_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.high_contribution_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="md:flex md:flex-row md:align-middle py-4 px-2 text-neutral-500">
    <span class="flex items-center justify-center text-ocean-600 md:pr-6">
      <MaterialIcon @icon="done" class="text-2xl" />
    </span>
    <span class="">
      <div class="p-2 text-base font-bold">
        {{t "planning_for_college.five_two_nine.flexibility_title"}}
      </div>
      <div class="p-2 pb-4 text-base">
        {{t "planning_for_college.five_two_nine.flexibility_text"}}
      </div>
    </span>
  </div>
  <Divider class="my-2" />
  <div class="flex justify-center items-center py-10 px-2">
    <img
      class="w-full max-w-96"
      alt={{t "planning_for_college.five_two_nine.piggy_bank"}}
      src="/assets/images/planning-for-college/529-piggy.png"
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FiveTwoNineBenefitsComponent;
