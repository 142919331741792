import { template as template_c11bd4ce31274373b62e25c1739fefc6 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import NavGroupsAdminPslf from './nav-groups/admin/pslf';
interface AppSidebarPslfAdminNavSignature {
    Args: {
        nav: unknown;
    };
}
const AppSidebarPslfAdminNavComponent: TOC<AppSidebarPslfAdminNavSignature> = template_c11bd4ce31274373b62e25c1739fefc6(`
  {{#let @nav as |nav|}}
    <NavGroupsAdminPslf @nav={{nav}} />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarPslfAdminNavComponent;
