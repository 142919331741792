import { template as template_3bfe44f227a44e7bbe0f7ed1cd46619c } from "@ember/template-compiler";
import { action } from '@ember/object';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import RouteTemplate from 'ember-route-template';
import { isNullish } from 'tio-common/utils/is-nullish';
import type { ErrorsSignature } from 'tio-common/components/tio/error-messages';
import type SessionContextService from 'tio-employee/services/session-context';
import SyfEnrollmentComplete from 'tio-employee/components/syf/enrollment-complete';
import SyfAccountConfirmation from 'tio-employee/components/syf/account-confirmation';
import SyfAlternateFlowEnrollment from 'tio-employee/components/syf/alternate-flow-enrollment';
import type { AuthenticatedSyfAccountConfirmationRouteModel } from 'tio-employee/routes/authenticated/syf/account-confirmation';
interface RouteSignature {
    Args: {
        model: AuthenticatedSyfAccountConfirmationRouteModel;
    };
}
class SyfAccountConfirmationRouteComponent extends Component<RouteSignature> {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @tracked
    loading: boolean = false;
    @tracked
    errors: ErrorsSignature | string | undefined;
    @tracked
    finished = false;
    get hasLinkedAccounts() {
        return !!this.args.model.person.activeAccounts.length;
    }
    get sumRegularMonthlyPaymentInCents() {
        return (this.args.model.person.activeAccounts.reduce((acc, account)=>{
            return acc + (account.providerRegularMonthlyPayment || 0);
        }, 0) * 100);
    }
    @action
    async didConfirmEnrollment() {
        try {
            this.loading = true;
            const matchParticipant = this.args.model.matchParticipant;
            await matchParticipant.activateMatchParticipant();
            if (isNullish(matchParticipant.reportedMonthlyPayment)) {
                matchParticipant.reportedMonthlyPayment = this.sumRegularMonthlyPaymentInCents;
                await matchParticipant.save();
            }
            this.finished = true;
        } catch (e) {
            if (e.isAdapterError) {
                this.errors = e;
            } else {
                this.errors = 'Activation failed.';
            }
        } finally{
            this.loading = false;
        }
    }
    @action
    didCancel() {
        this.router.transitionTo('authenticated.syf.enrollment');
    }
    static{
        template_3bfe44f227a44e7bbe0f7ed1cd46619c(`
    {{#if this.finished}}
      <SyfEnrollmentComplete />
    {{else if this.hasLinkedAccounts}}
      <SyfAccountConfirmation
        @accounts={{@model.person.activeAccounts}}
        @matchPlan={{@model.matchPlan}}
        @onConfirmation={{this.didConfirmEnrollment}}
        @onCancel={{this.didCancel}}
        @loading={{this.loading}}
        @errors={{this.errors}}
      />
    {{else if (not this.hasLinkedAccounts)}}
      <SyfAlternateFlowEnrollment
        @onConfirmation={{this.didConfirmEnrollment}}
        @matchPlan={{@model.matchPlan}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfAccountConfirmationRouteComponent);
