import { template as template_dbc1fef630b9408fbce95d02d6a24506 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { eq } from 'tio-ui/utilities';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { hash } from '@ember/helper';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import StatusText from 'tio-employee/components/slr/status-text';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import Tile from '../tile';
import TioButton from 'tio-common/components/tio/button';
import { Divider } from 'tio-ui/components/utilities';
import Tippy from 'ember-tippy/components/tippy';
import type { IntlService } from 'ember-intl';
import type { PastContributionPayment } from 'tio-employee/services/employee';
import type EmployeeService from 'tio-employee/services/employee';
import type SessionContextService from 'tio-employee/services/session-context';
export default class DashboardWidgetsStudentLoanRepaymentComponent extends Component {
    @service
    employee: EmployeeService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @tracked
    isLinkDisabled = false;
    get sortedContributions(): PastContributionPayment[] {
        return this.employee.contributionPayments.sort((a: PastContributionPayment, b: PastContributionPayment)=>{
            return new Date(b.line_item_date).getTime() - new Date(a.line_item_date).getTime();
        });
    }
    get lastPaymentAmount() {
        return (this.sortedContributions[0]?.amount || 0) / 100;
    }
    get lastPaymentDate() {
        return (this.sortedContributions[0]?.transaction_date || this.sortedContributions[0]?.line_item_period || '');
    }
    get lastPaymentStatus() {
        if (this.sortedContributions[0]?.transaction_date) {
            return this.intl.t('slr.payment_sent');
        } else if (this.sortedContributions[0]?.line_item_date) {
            return this.intl.t('slr.in_progress');
        } else return this.intl.t('slr.pending');
    }
    get nextContributionPayment() {
        return (this.employee.futureContributionPayments.find((payment)=>!payment.validation_errors.length) || this.employee.futureContributionPayments[0]);
    }
    get nextPaymentAmount() {
        return (this.nextContributionPayment?.amount || 0) / 100;
    }
    get nextPaymentDate() {
        return this.nextContributionPayment?.date || '';
    }
    get nextPaymentStatus() {
        const status = this.nextContributionPayment?.state || '';
        if (status === 'PENDING_APPROVAL') {
            return this.intl.t('slr.pending');
        }
        return status.replace(/_/g, ' ').toLowerCase();
    }
    get totalPayments() {
        return this.employee.contributionSummary?.total / 100 || 0;
    }
    // get totalYearlyPayments() {
    //   return this.employee.annualPreviewData?.annual_projection || 0;
    // }
    get hasAccounts() {
        return !!this.sessionContext.user?.accounts?.length;
    }
    get needsToLinkLoans() {
        return this.sessionContext.user && !this.hasAccounts;
    }
    get eligibleStartDate() {
        return this.employee.current.eligibilityStartDate || '';
    }
    get route() {
        return this.needsToLinkLoans ? 'authenticated.observability.upload' : 'authenticated.slr.dashboard';
    }
    get query() {
        return this.needsToLinkLoans ? {
            source: 'slr'
        } : {};
    }
    get hasError() {
        return this.nextContributionPayment?.validation_errors.length;
    }
    @action
    onTippyShow() {
        if ('ontouchstart' in window) {
            console.log('touchstart detected, disabling link');
            this.isLinkDisabled = true;
        }
    }
    @action
    onTippyHide() {
        this.isLinkDisabled = false;
    }
    static{
        template_dbc1fef630b9408fbce95d02d6a24506(`
    <LinkTo @route={{this.route}} @disabled={{this.isLinkDisabled}} @query={{this.query}}>
      <Tile @headerText={{t "dashboard_tiles.student_loan_repayment"}}>
        <:body>
          {{#if this.needsToLinkLoans}}
            <div class="flex flex-col items-center h-[calc(100%-2.5rem)] space-y-4">
              {{svgJar
                "coin-folder"
                width="80%"
                height="90%"
                role="img"
                desc=(t "svg.coin_folder")
              }}
              <p>{{t "dashboard_tiles.slr_link_loan_1" htmlSafe=true}}{{if
                  this.eligibleStartDate
                  (t "dashboard_tiles.slr_link_loan_2" htmlSafe=true)
                  "."
                }}</p>
              {{#if this.eligibleStartDate}}
                <Tippy
                  @placement="bottom"
                  @target="#start-date-copy"
                  @onShow={{this.onTippyShow}}
                  @onHidden={{this.onTippyHide}}
                >
                  <div class="flex flex-col space-y-3">
                    <h3>{{t "dashboard_tiles.slr_start_date_tip_header"}}</h3>
                    <p>{{t "dashboard_tiles.slr_start_date_tip_body"}}
                      {{safeFormatDate this.eligibleStartDate month="short" year="numeric"}}</p>
                  </div>
                </Tippy>
              {{/if}}
              <TioButton
                class="w-5/12 mt-auto"
                @outlined={{true}}
                @linkTo="authenticated.observability.upload"
                @query={{hash source="slr"}}
              >{{t "dashboard_tiles.slr_link_loans_btn_text"}}</TioButton>
            </div>
          {{else if this.hasError}}
            <MaterialIcon @icon="warning" class="text-red-500 mt-6 mb-2 mx-auto" />
            <h2 class="text-xl font-medium mb-4 text-center">{{t
                "dashboard_tiles.slr_needs_attention_header"
              }}</h2>
            <p class="text-center px-4">
              {{t "dashboard_tiles.slr_needs_attention_body"}}
            </p>
            <TioButton
              class="w-5/12 m-auto"
              @outlined={{true}}
              @linkTo="authenticated.slr.dashboard"
            >{{t "dashboard_tiles.view_details"}}</TioButton>
          {{else}}
            <div class="flex flex-col space-y-4">
              {{#if this.lastPaymentAmount}}
                <div class="flex items-center justify-between">
                  {{t "dashboard_tiles.slr_employer_contribution"}}
                  <span class="bg-gray-100 px-3 rounded">
                    {{safeFormatDate this.lastPaymentDate month="short" year="numeric"}}
                  </span>
                </div>
                <div class="flex items-center justify-between text-lg">
                  <div class="flex items-center text-2xl">
                    {{safeFormatNumber this.lastPaymentAmount style="currency" currency="USD"}}
                    {{#if (eq this.lastPaymentStatus "Payment Sent")}}
                      <MaterialIcon
                        @icon="check_circle"
                        @style="outlined"
                        class="ml-1 text-violet-300"
                      />
                    {{/if}}
                  </div>
                  <StatusText
                    @status={{this.lastPaymentStatus}}
                    @lastPaymentDate={{this.lastPaymentDate}}
                    @onShow={{this.onTippyShow}}
                    @onHide={{this.onTippyHide}}
                  />
                </div>
                <Divider />
              {{/if}}
              {{#if this.nextPaymentAmount}}
                <div class="flex items-center justify-between">
                  {{t "dashboard_tiles.slr_next_payment"}}
                  <span class="bg-gray-100 px-3 rounded">
                    {{safeFormatDate this.nextPaymentDate month="short" year="numeric"}}
                  </span>
                </div>
                <div class="flex items-center justify-between text-lg">
                  <span class="text-2xl">
                    {{safeFormatNumber this.nextPaymentAmount style="currency" currency="USD"}}
                  </span>
                  <StatusText
                    @status={{this.nextPaymentStatus}}
                    @onShow={{this.onTippyShow}}
                    @onHide={{this.onTippyHide}}
                  />
                </div>
                <Divider />
              {{/if}}
              <div class="flex items-center justify-between">
                {{t "dashboard_tiles.slr_employer_contributions"}}
              </div>
              <div class="flex items-center justify-between text-lg">
                <span class="text-2xl">
                  {{safeFormatNumber this.totalPayments style="currency" currency="USD"}}
                </span>
              </div>
              {{!-- {{#if this.totalYearlyPayments}}
                <div class="text-violet-300">
                  {{#let
                    (safeFormatNumber
                      this.totalYearlyPayments
                      style="currency"
                      currency="USD"
                      maximumFractionDigits=0
                    )
                    as |total|
                  }}
                    {{t "dashboard_tiles.slr_total_future_payments" total=total htmlSafe=true}}
                  {{/let}}
                </div>
              {{/if}} --}}
            </div>
          {{/if}}
        </:body>
      </Tile>
    </LinkTo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
