import { template as template_6e788de965644e7fa4089bc09aac9a9a } from "@ember/template-compiler";
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TuitionAssistanceDashboardMyPaymentActivity from 'tio-employee/components/tuition-assistance/dashboard/my-payment-activity';
import TuitionAssistanceDashboardMyPrograms from 'tio-employee/components/tuition-assistance/dashboard/my-programs';
import type { LimitsStatusDataSignature } from 'tio-common/services/tuition-assistance';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import User from 'ember-static-heroicons/components/outline-24/user';
export interface S {
    Args: {
        model: {
            isHeldParticipant: boolean;
            activeInstances: TasProgramInstanceModel[];
            templates: TasProgramTemplate[];
            pastInstances: TasProgramInstanceModel[];
            limitsStatus: LimitsStatusDataSignature;
        };
    };
}
export default RouteTemplate<S>(template_6e788de965644e7fa4089bc09aac9a9a(`
    {{! template-lint-disable no-bare-strings }}
    {{! TODO: don't be lazy and actually use the intl service }}
    {{pageTitle (t "dashboard")}}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      {{#if @model.isHeldParticipant}}
        <TioAlert @type="warning" class="my-4" @allowDismiss={{true}}>
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/if}}
      <Section>
        <:header>
          <div class="flex justify-between items-center">
            <p>{{t "tuition_assistance.dashboard.payment_activity.payments"}}</p>
            <LinkTo
              @route="authenticated.tuition-assistance.my-payment-details"
              class="flex items-center tio-copy gap-x-2"
            >
              {{t "tuition_assistance.dashboard.payment_activity.payment_details"}}
              <span class="no-underline">
                <ChevronRight class="w-4" />
              </span>
            </LinkTo>
          </div>
        </:header>
        <:body>
          <h3 class="font-semibold mt-4">
            {{t "tuition_assistance.dashboard.payment_activity.total_payments"}}
          </h3>
          <p>
            {{t "tuition_assistance.dashboard.payment_activity.note"}}
          </p>
          <TuitionAssistanceDashboardMyPaymentActivity
            class="m-2 mb-16"
            @limitsStatus={{@model.limitsStatus}}
          />
        </:body>
      </Section>
      <Section>
        <:header>
          <div class="flex justify-between items-center">
            <p>Active Assistance</p>
            <DependentsButton />
          </div>
        </:header>
        <:body>
          <VStack @collapsed={{true}}>
            {{#each @model.activeInstances as |instance|}}
              <Section class="flex justify-between items-start">
                <p>
                  {{instance.programName}}
                  {{instance.state}}
                  {{#each instance.tasApplications as |application|}}
                    {{application.state}}
                  {{/each}}
                </p>
                <LinkTo
                  @route="authenticated.tuition-assistance.programs.instance"
                  @model={{instance.id}}
                  class="text-blue-800 flex gap-x-2"
                >
                  {{! TODO: apply versus enrolled}}
                  {{t "tuition_assistance.details"}}
                  <ChevronRight class="w-4" />
                </LinkTo>
                {{! TODO: Figma shows an assistance, but there can be may. How will this actually work?}}
              </Section>
            {{/each}}
          </VStack>
        </:body>
      </Section>
      <Section>
        <:header>Available Education Benefits</:header>
        <:body>
          <VStack @collapsed={{true}}>
            {{#each @model.templates as |template|}}
              <Section>
                <p class="flex justify-between">
                  {{template.programName}}
                  {{! TODO: points to old UI}}
                  <LinkTo
                    @route="authenticated.tuition-assistance.program-overview"
                    @model={{template.id}}
                    class="text-blue-800 flex gap-x-2"
                  >
                    {{! TODO: apply versus enrolled}}
                    {{t "tuition_assistance.details"}}
                    <ChevronRight class="w-4" />
                  </LinkTo>
                </p>
                <p class="text-sm">
                  {{template.programDescription}}
                </p>
              </Section>
            {{/each}}
          </VStack>
        </:body>
      </Section>
      {{! TODO: figure out the logic on when we show this. We want to show things that only closed out recently
      in the main list. Thinking like 30 days. Also figma shows this as a history page on it's own, but that placement is
      currently occupied by the dependent button so we need a home for that first? }}
      {{#if @model.pastInstances.length}}
        <Section>
          <:body>
            <TuitionAssistanceDashboardMyPrograms
              @programStatus="past"
              @programInstances={{@model.pastInstances}}
              @sectionHeader={{t "tuition_assistance.dashboard.past_programs"}}
              class="mb-12"
            />
          </:body>
        </Section>
      {{/if}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
class DependentsButton extends Component {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    get isDependentProgramsEnabled() {
        return (this.sessionContext.currentEmployee?.company.companySetting.tuitionAssistance?.isDependentProgramsEnabled || false);
    }
    get showDependentProgramsButton(): boolean {
        return (this.router.currentRouteName === 'authenticated.tuition-assistance.dashboard' && this.isDependentProgramsEnabled);
    }
    static{
        template_6e788de965644e7fa4089bc09aac9a9a(`
    <Button
      @intent="primary"
      @appearance="outlined"
      {{on "click" (transitionTo "authenticated.tuition-assistance.dependent-programs")}}
      data-test-dependent-programs-button
      class="flex items-end"
    >
      <User class="w-4 mr-2" />
      {{t "common.tuition_assistance.dependent_programs_button"}}
    </Button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
