import { template as template_fff5a0e822b2407db94522ad9a0c5073 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { htmlSafe } from '@ember/template';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import ArrowDownTray from 'ember-static-heroicons/components/outline-24/arrow-down-tray';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import Badge from 'tio-ui/components/badge';
import type { Intent } from 'tio-ui/utilities';
import { and, eq } from 'tio-ui/utilities';
import { getStatus, getProvider, getDisputeNote } from 'tio-common/helpers/observability';
import AccountSummary from 'tio-employee/components/observability/account-summary';
import { VStack, Header, Section } from 'tio-ui/components/layout';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
interface RouteSignature {
    Args: {
        model: ObservableDocumentModel;
    };
}
const getStatusIntent = (status: string): Intent | undefined =>{
    switch(status){
        case 'PROCESSING':
            return 'primary';
        case 'INVALID':
        case 'FAILED':
        case 'NOT_APPLIED':
            return 'error';
        case 'PROCESSED':
        case 'APPLIED':
            return 'success';
        case 'IN_DISPUTE':
        case 'PARTIALLY_APPLIED':
            return 'warning';
    }
};
const getSubmittedIcon = (via: string)=>{
    if (via === 'EMAIL') {
        return Envelope;
    }
    return DocumentArrowUp;
};
const formatDisputeNote = (note?: string): string | undefined =>{
    if (note) {
        return `"${note}"`;
    }
};
class ObservabilityDocumentsShow extends Component<RouteSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get documentCreated() {
        const observableDocument = this.args.model;
        const createdAtDate = new Date(observableDocument.createdAt);
        const date = createdAtDate.toLocaleDateString();
        const time = createdAtDate.toLocaleTimeString();
        const via = this.intl.t(`observability.${observableDocument.via}`);
        return {
            via,
            date,
            time
        };
    }
    get amendLink(): string {
        const linkText = this.intl.t('click_here').toLowerCase();
        const url = this.router.urlFor('authenticated.observability.dispute', this.args.model.id);
        return htmlSafe(`<a href="${url}" class="tio-anchor">${linkText}</a>`) as unknown as string;
    }
    get documentSuccessful() {
        return this.args.model?.extractionState === 'ExtractionState.PROCESSED';
    }
    get documentUnsuccessful() {
        return [
            'ExtractionState.FAILED',
            'ExtractionState.INVALID'
        ].includes(this.args.model?.extractionState);
    }
    get documentInReview() {
        return this.args.model?.subsistenceState === 'SubsistenceState.IN_DISPUTE';
    }
    get documentPartiallyApplied() {
        return this.args.model?.reflectionState === 'ReflectionState.PARTIALLY_APPLIED';
    }
    static{
        template_fff5a0e822b2407db94522ad9a0c5073(`
    <VStack>
      <Header>
        <TioPageBreadcrumbs as |b|>
          <b.crumb
            @route="authenticated.account-activity.accounts"
            @label={{t "accounts.my_accounts"}}
          />
          <b.crumb @label={{t "observability.document.summary.header"}} />
        </TioPageBreadcrumbs>
      </Header>
      <Section>
        <:header>
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <span>{{@model.observableAsset.filename}}</span>
              <Badge @intent={{getStatusIntent (getStatus @model)}} class="ml-4">
                {{t "observable_document.status" state=(getStatus @model)}}
              </Badge>
            </div>
            <a
              href={{@model.observableAsset.signedUrl}}
              target="_blank"
              rel="noopener noreferrer"
              class="tio-btn tio-btn-default tio-btn-outlined ml-auto"
            >
              <ArrowDownTray class="inline w-4 mr-1" />
              {{t "download"}}
            </a>
          </div>
        </:header>
        <:body>
          <h2 class="tio-h3 my-4">
            {{t "observable_document.provider" provider=(getProvider @model)}}</h2>
          <div class="italic flex items-center">
            {{#let (getSubmittedIcon @model.via) as |SubmittedIcon|}}
              <SubmittedIcon class="w-6 mr-2" />
            {{/let}}
            {{t
              "observability.dispute.document.uploaded"
              via=this.documentCreated.via
              date=this.documentCreated.date
              time=this.documentCreated.time
            }}
          </div>
          {{#if this.documentSuccessful}}
            <AccountSummary @document={{@model}} class="w-full md:w-2/3 my-8" as |summary|>
              {{#if (and this.documentInReview (getDisputeNote @model))}}
                <summary.footer>
                  {{formatDisputeNote (getDisputeNote @model)}}
                </summary.footer>
              {{else if (eq @model.subsistenceState "SubsistenceState.IMPLICIT")}}
                <summary.footer>
                  {{! TODO: genericize this instead of stealing it from the confirmation page }}
                  {{t "observability.confirmation.amend" clickHere=this.amendLink htmlSafe=true}}
                </summary.footer>
              {{/if}}
            </AccountSummary>
            {{#if (eq @model.reflectionState "ReflectionState.PARTIALLY_APPLIED")}}
              <p class="my-8">
                {{t "observability.document.summary.partially_applied"}}
              </p>
            {{/if}}
          {{else if this.documentUnsuccessful}}
            <p class="text-red-700 my-8">
              {{t "observability.documents.show.unsuccessful"}}
            </p>
            <p class="my-4">
              {{t "observability.uploads.support.prompt"}}
              <span class="block text-sm my-2">
                {{htmlSafe
                  (t
                    "observability.uploads.support.email"
                    email="support@tuition.io"
                    linkClass="tio-link"
                  )
                }}
              </span>
              <span class="block text-sm my-2">
                {{t "observability.uploads.support.phone"}}
              </span>
            </p>
          {{/if}}
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ObservabilityDocumentsShow);
