import { template as template_3799e1fe89a344dab0b44b3abc5b5026 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface DashboardGridLayoutSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const DashboardGridLayoutComponent: TOC<DashboardGridLayoutSignature> = template_3799e1fe89a344dab0b44b3abc5b5026(`
  <div
    class="w-full p-2 lg:p-3 xl:p-4 grid grid-rows-[fit-content(100%)] gap-3 xl:gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center"
    ...attributes
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardGridLayoutComponent;
