import { template as template_ec26825168074e95885f2938e5278b41 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { Divider } from 'tio-ui/components/utilities';
import { fn } from '@ember/helper';
import { hasInProgressInstanceOfCurrentProgramTemplate } from 'tio-common/utils/tuition-assistance/tas-grouping';
import { Input, Select } from 'tio-ui/components/forms';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { or, eq, not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import { TrackedObject } from 'tracked-built-ins';
import { getAllValuesForTasField, copyFieldsAndUpdatePerProgramTemplate, getSingleValueForTasField, setSingleValueForTasField, getConfigForTasField, type FieldName } from 'tio-common/utils/tuition-assistance/fields';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import inputmask from 'tio-common/modifiers/inputmask';
import ProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import SchoolSelect from 'tio-common/components/tuition-assistance/program-details/school-select';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import Trash from 'ember-static-heroicons/components/outline-24/trash';
import TuitionAssistanceFormsCustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type { TASProgramInstanceModelFieldsSignature } from 'tio-common/models/tas-program-instance';
import type { TASProgramTemplateModelFieldsSignature } from 'tio-common/models/tas-program-template';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateInstitutionModel from 'tio-common/models/tas-program-template-institution';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import type Owner from '@ember/owner';
const number = Number;
export interface NewTypeOneOrFourSignature {
    Args: {
        model: {
            activeInstances: TASProgramInstanceModel[];
            programInstance: TASProgramInstanceModel;
            selectedSchool: TasUnifiedInstitutionModel | undefined;
            tasGroups: TasGroupModel[];
        };
    };
}
type ProgramInstanceFieldName = keyof TASProgramTemplateModelFieldsSignature;
export type FormField = {
    name: ProgramInstanceFieldName;
    mask: string | null;
    type: string | null;
    rules: {
        type: string;
        typeError?: string;
    };
    disabled: boolean;
};
export default class NewTypeOneOrFourComponent extends Component<NewTypeOneOrFourSignature> {
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    customFieldsCopy: CustomFieldSignature[] = [];
    @tracked
    isDeleteProgramOpen = false;
    @tracked
    isOpen = true;
    @tracked
    selectedSchool?: TasUnifiedInstitutionModel;
    @tracked
    hasSubmitted = false;
    @tracked
    submitError = null;
    programFieldsCopy;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    constructor(owner: Owner, args: NewTypeOneOrFourSignature['Args']){
        super(owner, args);
        this.programFieldsCopy = new TrackedObject(args.model.programInstance.fields);
        const programTemplateCustomFields = args.model.programInstance.tasProgramTemplate?.instanceCustomFields || [];
        this.customFieldsCopy = copyFieldsAndUpdatePerProgramTemplate(args.model.programInstance.customFields, programTemplateCustomFields);
        this.setSelectedSchoolIfExists();
    }
    setSelectedSchoolIfExists() {
        if (this.args.model.selectedSchool) {
            this.selectedSchool = this.args.model.selectedSchool;
        }
    }
    // TAS.TODO: need custom field validation
    get customFieldValidationErrors() {
        return [];
    }
    get programFormFields(): FormField[] {
        const numberTypeError = 'Value must be a number';
        return [
            {
                name: 'NAME',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'APPLICATION_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'EMPLOYEE_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'FT_PT_OTHER',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'EMPLOYEE_DOB',
                mask: null,
                type: 'date',
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'EMPLOYEE_EMAIL',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'SCHOOL_INSTITUTION_NAME',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'STUDENT_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'PROGRAM_SPECIALIZATION',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'PROGRAM_MAJOR',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_COST',
                mask: 'currency',
                type: 'number',
                rules: {
                    type: 'number',
                    typeError: numberTypeError
                },
                disabled: false
            },
            {
                name: 'ENROLLMENT_OBJECTIVE',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_PROGRAM_BEGIN',
                mask: null,
                type: 'date',
                rules: {
                    type: 'date'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_PROGRAM_COMPLETION',
                mask: null,
                type: 'date',
                rules: {
                    type: 'date'
                },
                disabled: false
            }
        ];
    }
    get programTemplate() {
        return this.args.model.programInstance.tasProgramTemplate;
    }
    get requireAttachmentUploads() {
        return this.programTemplate.requireAttachmentsForProgramApproval;
    }
    get hasRequiredAttachments() {
        if (this.requireAttachmentUploads) {
            return !!this.args.model.programInstance.tasAssets?.length;
        }
        return true;
    }
    get attachmentsListDescription() {
        return (getAllValuesForTasField('PROGRAM_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get conditionsForSubmit() {
        return this.programTemplate?.programApproveConditions || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get canSubmitApplication() {
        return (this.args.model.programInstance.state === 'TAS.ProgramInstanceState.DRAFT' && this.hasAgreedAllConditions && this.hasRequiredAttachments && this.isFormValid && this.requiredCustomFields);
    }
    get isFormValid() {
        let fieldValues = [];
        for (const field of this.programFormFields){
            const config = getConfigForTasField(field.name, this.programTemplate.fields);
            if (config.required) {
                const value = getSingleValueForTasField(field.name, this.programFieldsCopy);
                fieldValues.push(value);
            }
        }
        return fieldValues.every((value)=>value);
    }
    get requiredCustomFields() {
        for (const field of this.customFieldsCopy){
            if (field.required && !field.values?.length) {
                return false;
            }
        }
        return true;
    }
    get isRestrictedByProgramExclusivity() {
        if (this.args.model.tasGroups.length === 0) {
            return false;
        }
        return hasInProgressInstanceOfCurrentProgramTemplate(this.args.model.tasGroups, this.programTemplate.id, this.args.model.activeInstances);
    }
    featuredSchoolData = trackedFunction(this, async ()=>{
        const template = this.args.model.programInstance.tasProgramTemplate;
        const schools = await this.store.query<TasProgramTemplateInstitutionModel[]>('tas-program-template-institution', {
            filter: {
                'is-featured': true,
                'tas-program-template': template.id
            },
            include: 'accredited-institution,unaccredited-institution,logo'
        });
        return schools;
    });
    get featuredSchools() {
        return this.featuredSchoolData.value || [];
    }
    get customEmployeeFields(): CustomFieldSignature[] {
        return this.customFieldsCopy.filter((field)=>field.province === 'EMPLOYEE');
    }
    get customProgramFields(): CustomFieldSignature[] {
        return this.customFieldsCopy.filter((field)=>field.province === 'PROGRAM');
    }
    @action
    didSelectSchool(model?: TasUnifiedInstitutionModel) {
        this.selectedSchool = model;
        setSingleValueForTasField(// @ts-expect-error: overload matches
        'SCHOOL_INSTITUTION_NAME', model?.locationName, this.programFieldsCopy);
        // @ts-expect-error: is the expectation that we can remove value or should we wrap in if?
        setSingleValueForTasField('TAS_INSTITUTION_ID', model?.institutionId, this.programFieldsCopy);
        // @ts-expect-error: is the expectation that we can remove value or should we wrap in if?
        setSingleValueForTasField('DAPIP_INSTITUTION_ID', model?.dapipId, this.programFieldsCopy);
    }
    @action
    didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
        this.acceptedConditions = conditions;
    }
    @action
    toggleDeleteProgram() {
        this.isDeleteProgramOpen = !this.isDeleteProgramOpen;
    }
    @action
    async onSave() {
        try {
            this.args.model.programInstance.fields = this.programFieldsCopy;
            this.args.model.programInstance.customFields = this.customFieldsCopy;
            await this.args.model.programInstance.save();
        } catch (error) {
            console.error('Failed to save program instance:', error);
        }
    }
    @action
    getAllValuesForField(fieldName: keyof TASProgramTemplateModelFieldsSignature) {
        return getAllValuesForTasField(fieldName as keyof TASProgramInstanceModelFieldsSignature, this.programFieldsCopy) as string[];
    }
    @action
    updateValueForField(fieldName: FieldName, fields: TASProgramInstanceModelFieldsSignature, type: string | null, value: string[] | string | number, event?: Event | undefined) {
        let valueToSet = Array.isArray(value) ? value[0] : value;
        // @ts-expect-error: input mask types are not correct
        if (event?.target?.inputmask?.userOptions?.alias === 'currency') {
            // @ts-expect-error: input mask types are not correct
            valueToSet = event.target.inputmask?.unmaskedvalue?.() * 100;
        }
        if (type === 'number') {
            valueToSet = Math.round(Number(valueToSet));
        }
        // @ts-expect-error: fieldName
        setSingleValueForTasField(fieldName, valueToSet, fields);
    }
    updateFieldsWithNewValues = (existingFields: CustomFieldSignature[], updatedFields: CustomFieldSignature[]): CustomFieldSignature[] =>{
        updatedFields.forEach((newObject)=>{
            const index = existingFields.findIndex((item)=>item.label === newObject.label && item.province === newObject.province);
            existingFields.splice(index, 1, newObject);
        });
        return existingFields;
    };
    @action
    async didUpdateEmployeeCustomFields(customFields: CustomFieldSignature[]) {
        let updatedFields = this.updateFieldsWithNewValues(this.customEmployeeFields, customFields);
        this.customFieldsCopy = this.updateFieldsWithNewValues(this.customFieldsCopy, updatedFields);
    }
    @action
    async didUpdateProgramCustomFields(customFields: CustomFieldSignature[]) {
        let updatedFields = this.updateFieldsWithNewValues(this.customProgramFields, customFields);
        this.customFieldsCopy = this.updateFieldsWithNewValues(this.customFieldsCopy, updatedFields);
    }
    deleteProgram = dropTask(async ()=>{
        try {
            await this.store.adapterFor('tas-program-instance').abandonProgram(this.args.model.programInstance);
            // TAS.TODO: temporary routing until tas dashboard is complete
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        } catch (e) {
            console.error(e);
        }
    });
    saveAgreementsForConditions = dropTask(async ()=>{
        const user = this.sessionContext.user;
        const agreements = this.acceptedConditions.map((condition)=>{
            return this.store.createRecord('agreement', {
                user: user,
                condition: condition
            });
        });
        const promises = agreements.map((agreement)=>{
            return agreement.save();
        });
        const saved = await all(promises);
        return saved;
    });
    submitTask = dropTask(async ()=>{
        if (!this.isFormValid || !this.hasAgreedAllConditions || this.isRestrictedByProgramExclusivity) {
            return;
        }
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.saveAgreementsForConditions.perform();
            await this.onSave();
            await this.store.adapterFor('tas-program-instance').requestProgramApproval(this.args.model.programInstance);
            this.router.transitionTo('authenticated.tas.programs.show', this.args.model.programInstance.id);
        } catch (e) {
            console.error(e);
            this.submitError = e;
        }
    });
    static{
        template_ec26825168074e95885f2938e5278b41(`
    <VStack>
      <Header class="flex justify-between items-center">
        <div>
          {{! TAS.TODO: temporary routing until tas dashboard is complete }}
          <LinkTo
            class="flex justify-left items-center gap-4"
            @route="authenticated.tuition-assistance.dashboard"
          >
            <ChevronLeft class="w-5 inline" />
            {{t "dashboard"}}
          </LinkTo>
        </div>
        <div class="flex items-center gap-4">
          <Button
            data-test-save-application
            @intent="primary"
            @appearance="outlined"
            {{on "click" this.onSave}}
          >
            {{t "save"}}
          </Button>
          <Button
            @intent="danger"
            @appearance="outlined"
            data-test-delete-application
            {{on "click" this.toggleDeleteProgram}}
          >
            <Trash class="w-5 inline" />
          </Button>
        </div>
      </Header>
      <HStack>
        <Section class="w-full md:!w-2/3">
          <:header>
            <div>{{t "tas.program.approval"}}</div>
            <div class="font-normal">
              <span class="text-error-400 text-xl">*</span>
              {{t "tas.program.required_items"}}
            </div>
          </:header>
          <:body>
            {{#each this.programFormFields as |programField|}}
              {{#if (eq programField.name "SCHOOL_INSTITUTION_NAME")}}
                <SchoolSelect
                  @programTemplate={{this.programTemplate}}
                  @labelClass="!text-gray-600 font-semibold !text-base w-1/2"
                  @selected={{this.selectedSchool}}
                  @onChange={{this.didSelectSchool}}
                  class="mb-2 flex flex-wrap justify-between gap-y-4 items-center [&>div]:w-1/2 [&>div]:border [&>div]:border-default-400 [&>div]:rounded-lg"
                />
                <Divider class="my-4" />
              {{else}}
                <ProgramFormField
                  @name={{programField.name}}
                  @programTemplate={{this.programTemplate}}
                  @labelClass="!text-gray-600 font-semibold !text-base w-1/2"
                  class="flex flex-wrap justify-between gap-y-4 items-center
                    {{if (eq programField.type 'date') '[&>div]:w-1/4' '[&>div]:w-1/2'}}"
                  as |field|
                >
                  {{#if field.isFieldOptionPicklist}}
                    <Select
                      data-test={{field.name}}
                      id={{field.inputId}}
                      {{! @glint-expect-error: how do we reconcile readonly }}
                      @items={{field.fieldOptions}}
                      @selectionMode="single"
                      @selectedKeys={{this.getAllValuesForField field.name}}
                      @onSelectionChange={{fn
                        this.updateValueForField
                        field.name
                        this.programFieldsCopy
                        programField.type
                      }}
                      disabled={{programField.disabled}}
                      {{!-- @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}} --}}
                    />

                  {{else if (eq programField.mask "currency")}}
                    <Input
                      data-test={{field.name}}
                      id={{field.inputId}}
                      @value="{{divide
                        (or
                          (number (getSingleValueForTasField field.name this.programFieldsCopy)) 0
                        )
                        100
                      }}"
                      @onChange={{fn
                        this.updateValueForField
                        field.name
                        this.programFieldsCopy
                        programField.type
                      }}
                      disabled={{programField.disabled}}
                      {{inputmask alias="currency" prefix="\$" unmaskAsNumber=false digits="2"}}
                      {{!-- @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}} --}}
                    />
                  {{else}}
                    <Input
                      data-test={{field.name}}
                      id={{field.inputId}}
                      @value={{getSingleValueForTasField field.name this.programFieldsCopy}}
                      @onChange={{fn
                        this.updateValueForField
                        field.name
                        this.programFieldsCopy
                        programField.type
                      }}
                      type={{programField.type}}
                      disabled={{programField.disabled}}
                    />
                  {{/if}}
                  <Divider class="mb-4" />
                </ProgramFormField>
              {{/if}}
            {{/each}}
            <TuitionAssistanceFormsCustomFieldsForProvince
              @province="EMPLOYEE"
              @customFields={{this.customEmployeeFields}}
              @didUpdateFields={{this.didUpdateEmployeeCustomFields}}
              @formValidationErrors={{this.customFieldValidationErrors}}
              @fieldContainerClass="pb-4"
            />
            <TuitionAssistanceFormsCustomFieldsForProvince
              @province="PROGRAM"
              @customFields={{this.customProgramFields}}
              @didUpdateFields={{this.didUpdateProgramCustomFields}}
              @formValidationErrors={{this.customFieldValidationErrors}}
              @fieldContainerClass="pb-4"
            />
            {{! Upload Attachments}}
            <div class="my-20">
              <div>
                <h1 class="text-grey-600 text-2xl">
                  {{t "attachments"}}
                  {{#if this.requireAttachmentUploads}}
                    <span class="text-error-400 text-2xl">*</span>
                  {{/if}}
                </h1>
                <p>{{t "tas.application.type_two.new.attachment_description"}}</p>
              </div>
              <div class="flex items-center w-full md:max-w-lg mb-4">
                <UploadAttachments
                  @programTemplate={{this.programTemplate}}
                  @classification="PROGRAM_APPROVAL_DOCUMENTS"
                  @attachable={{@model.programInstance}}
                  @attachableType="tasProgramInstance"
                  @useDropzone={{true}}
                  {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                />
              </div>
            </div>
          </:body>
        </Section>
        <Section class="w-full md:!w-1/3">
          <:header>
            <header
              class="bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -m-4"
            >
              <ClipboardDocumentList class="h-8 w-8" />
              <span>{{t "tas.benefits_summary.required_items"}}</span>
            </header>
          </:header>
          <:body>
            {{! Required Items Section - ASIDE}}
            <section class="pb-5">
              {{#if this.requireAttachmentUploads}}
                <div>
                  <div class="flex flex-row justify-between items-center">
                    <p>{{t "tas.benefits_summary.attachments"}}
                      <span class="text-error-400 ml-1">
                        *
                      </span>
                    </p>
                    <div>
                      <UploadAttachments
                        @programTemplate={{this.programTemplate}}
                        @classification="PROGRAM_APPROVAL_DOCUMENTS"
                        @attachable={{@model.programInstance}}
                        @attachableType="tasProgramInstance"
                        @useDropzone={{false}}
                        @iconOnlyUpload={{true}}
                        @hideAttachments={{true}}
                        @hideInstructions={{true}}
                        {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                      />
                    </div>
                  </div>
                  <div class="flex items-center gap-3 -mt-1">
                    {{#if this.hasRequiredAttachments}}
                      <CheckCircleSolid class="h-6 w-6 text-violet-500 shrink-0" />
                    {{else}}
                      <CheckCircle class="h-6 w-6 text-tio-gray-200 shrink-0" />
                    {{/if}}
                    <p>{{this.attachmentsListDescription}}</p>
                  </div>
                </div>
              {{/if}}

              {{#if this.conditionsForSubmit.length}}
                <AcceptConditions
                  @conditions={{this.conditionsForSubmit}}
                  @onChange={{this.didUpdateAcceptedConditions}}
                />
              {{/if}}
            </section>

            <div class="py-4">
              <Button
                @isRunning={{this.submitTask.isRunning}}
                class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                data-test-submit-program
                {{on "click" this.submitTask.perform}}
                disabled={{not this.canSubmitApplication}}
              >
                {{t "common.submit"}}
              </Button>

              <p class="text-sm font-light text-center">
                {{t "tas.benefits_summary.helper_text"}}
              </p>
              <TioErrorMessages
                @showErrors={{this.hasSubmitted}}
                @error={{this.submitError}}
                class="text-sm"
                @icon="warning"
              />
            </div>
          </:body>
        </Section>
      </HStack>
    </VStack>

    <Modal
      @isOpen={{this.isDeleteProgramOpen}}
      @onClose={{this.toggleDeleteProgram}}
      data-test-delete-program-modal
      as |m|
    >
      <m.Header>{{t "tas.program.delete_program"}}</m.Header>
      <m.Body>
        <p>{{t "tas.program.confirm_program_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteProgram.isRunning}}
          @onCancel={{this.toggleDeleteProgram}}
          @onSubmit={{this.deleteProgram.perform}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
