import { template as template_6e5a472b1e8d48f08b77eaa3430df3da } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
interface SlrRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SlrRouteSignature>(template_6e5a472b1e8d48f08b77eaa3430df3da(`
    <VStack>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
