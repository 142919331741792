import { template as template_71417a2c59984e988f162104a8e9d5a6 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import RecommendationsResultsResultsDetails from 'tio-employee/components/recommendations/results/results-details';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type RecommendationModel from 'tio-common/models/recommendation';
interface S {
    Args: {
        model: RecommendationModel;
    };
}
export default RouteTemplate<S>(template_71417a2c59984e988f162104a8e9d5a6(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
        @label="Dashboard"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.index"
        @label="Strategy Finder"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results"
        @label="Results"
      />
    </TioPageBreadcrumbs>
    <RecommendationsResultsResultsDetails @recommendation={{@model}} @programType="sf" />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
