import { template as template_e419cc3b300746e29cfada9529096a11 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
interface S {
    Args: {
        canInviteFamily: unknown;
        nav: unknown;
    };
    Element: HTMLDivElement;
}
const AppSidebarNavGroupsEmployeePlanningForCollegeComponent: TOC<S> = template_e419cc3b300746e29cfada9529096a11(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.group
      @icon="check_circle"
      @label="Planning for College"
      @nestedRoute="planning-for-college"
      ...attributes
      as |group|
    >
      <group.item @nestedRoute="college-cost-calculator" @label="College Financial Planner" />
      <group.item @nestedRoute="scholarship" @label="Scholarship Information" />
      <group.item @nestedRoute="529-tools" @label="529 Plans" />
      {{#if @canInviteFamily}}
        <group.item
          @route="authenticated.profile"
          @routeQuery={{hash parentDashboard="planning-for-college"}}
          @label="Invite Family Member"
        />
      {{/if}}
      {{! @glint-expect-error: no idea}}
    </nav.group>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsEmployeePlanningForCollegeComponent;
