import { template as template_488dcc5e0210475d9e3a8d8cfbb1a15f } from "@ember/template-compiler";
import { action } from '@ember/object';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import PslfDashboardCardInfo from 'tio-employee/components/pslf/dashboard/card-info';
import PslfDashboardTodo from 'tio-employee/components/pslf/dashboard/todo';
import PslfDashboardTodoCheckbox from 'tio-employee/components/pslf/dashboard/todo/checkbox';
import TioButton from 'tio-common/components/tio/button';
import type AnswerProfileModel from 'tio-common/models/answer-profile';
export interface RecommendationsRecertifyTodoSignature {
    Args: {
        answerProfile: AnswerProfileModel;
    };
}
export default class RecommendationsRecertifyTodoComponent extends Component<RecommendationsRecertifyTodoSignature> {
    get answerProfile() {
        return this.args.answerProfile;
    }
    get isCompleted() {
        if (this.answerProfile?.hasExpiredRecertification) {
            return false;
        }
        return !!this.answerProfile?.recertifiedIncomeAt;
    }
    @action
    async toggleCompleted() {
        const profile = this.answerProfile;
        try {
            profile.recertifiedIncomeAt = this.isCompleted ? '' : new Date().toISOString();
            await profile.save();
        } catch (e) {
            console.error(e);
            profile?.rollbackAttributes();
        }
    }
    static{
        template_488dcc5e0210475d9e3a8d8cfbb1a15f(`
    <PslfDashboardTodo
      @title={{t "recommendations.recertify_income"}}
      @isComplete={{this.isCompleted}}
    >
      <:leftHeader>
        <PslfDashboardTodoCheckbox
          @checked={{this.isCompleted}}
          @onClick={{this.toggleCompleted}}
        />
      </:leftHeader>
      <:body>
        <PslfDashboardCardInfo
          @icon="calculate"
          @text={{t "recommendations.need_recertify_income"}}
          class="w-fit"
        />
      </:body>
      <:actions>
        <TioButton
          @outlined={{true}}
          @href="https://studentaid.gov/idr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{t "recommendations.recertify_income"}}
        </TioButton>
      </:actions>
    </PslfDashboardTodo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
