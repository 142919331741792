import { template as template_cf0bb864a9ff475b8f0e222611204f4d } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import Table from 'tio-common/components/table/index';
import { APPLICATIONS, COURSES_IN_PROGRESS, COURSES_MISSING_DOCUMENTATION, PAYMENTS, TERMINATED_EMPLOYEES, INVOICE_DATE } from 'tio-common/components/reporting/tas/applications';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { Header, Section, VStack } from 'tio-ui/components/layout';
const ReportingIndexRoute: TOC = template_cf0bb864a9ff475b8f0e222611204f4d(`
  <VStack>
    <Header>{{t "common.reporting.default"}}</Header>
    <Section>
      <:header>{{t "reporting.default"}}</:header>
      <:body>
        <VStack>
          <p>{{t "common.reporting.description" htmlSafe=true}}</p>
          <Table @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "common.reporting.tas.report_name"}}</options.th>
                <options.th>{{t "common.reporting.tas.description"}}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              <options.tr>
                <options.td>
                  <LinkTo class="tio-anchor" @route="authenticated.admin.reporting.tas-payables">
                    {{t "common.reporting.tas.payables.description"}}
                  </LinkTo>
                </options.td>
                <options.td>{{t "common.reporting.tas.payables.description"}}</options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{PAYMENTS}}
                  >
                    {{t "common.reporting.tas.payments.title"}}
                  </LinkTo>
                </options.td>
                <options.td>{{t "common.reporting.tas.payments.description"}}</options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{COURSES_IN_PROGRESS}}
                  >
                    {{t "common.reporting.tas.courses.title"}}
                  </LinkTo>
                </options.td>
                <options.td>{{t "common.reporting.tas.courses.description"}}</options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{APPLICATIONS}}
                  >
                    {{t "common.reporting.tas.applications.title"}}
                  </LinkTo>
                </options.td>
                <options.td>
                  {{t "common.reporting.tas.applications.description"}}
                </options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{COURSES_MISSING_DOCUMENTATION}}
                  >
                    {{t "common.reporting.tas.missing_documentation.title"}}
                  </LinkTo>
                </options.td>
                <options.td>
                  {{t "common.reporting.tas.missing_documentation.description"}}
                </options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{INVOICE_DATE}}
                  >
                    {{t "common.reporting.tas.invoice_date.title"}}
                  </LinkTo>
                </options.td>
                <options.td>{{t "common.reporting.tas.invoice_date.description"}}</options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo
                    class="tio-anchor"
                    @route="authenticated.admin.reporting.tas-applications"
                    @query={{TERMINATED_EMPLOYEES}}
                  >
                    {{t "common.reporting.tas.terminated_employee.title"}}
                  </LinkTo>
                </options.td>
                <options.td>
                  {{t "common.reporting.tas.terminated_employee.description"}}
                </options.td>
              </options.tr>
              <options.tr>
                <options.td>
                  <LinkTo class="tio-anchor" @route="authenticated.admin.reporting.tas-programs">
                    {{t "common.reporting.tas.programs.title"}}
                  </LinkTo>
                </options.td>
                <options.td>
                  {{t "common.reporting.tas.programs.description"}}
                </options.td>
              </options.tr>
            </:tbody>
          </Table>
        </VStack>
      </:body>
    </Section>
  </VStack>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingIndexRoute);
