import { template as template_70af20f4302e432fafb075e924caf1f3 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_70af20f4302e432fafb075e924caf1f3(`
    <VStack>
      <Header>{{t "idr.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
