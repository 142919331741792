import { template as template_332e66b4b70748fa9825c4046f89ee04 } from "@ember/template-compiler";
import type Store from '@ember-data/store';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { service } from '@ember/service';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import FormTextarea from '@frontile/forms-legacy/components/form-textarea';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import { trackedFunction } from 'reactiveweb/function';
import { or } from 'tio-ui/utilities';
import ActionButtons from 'tio-common/components/tuition-assistance/action-buttons';
import FeaturedSchools from 'tio-common/components/tuition-assistance/featured-schools';
import ProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import errorsForField from 'tio-common/helpers/errors-for-field';
import screenIs from 'tio-common/helpers/screen-is';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import inputmask from 'tio-common/modifiers/inputmask';
import type { FieldName } from 'tio-common/utils/tuition-assistance/fields';
import { buildCustomFieldsFormModelForValidation, buildValidationSchemaForCustomFields, buildValidationSchemaForProgramTemplateFields, copyFieldsAndUpdatePerProgramTemplate, getSingleValueForTasField, setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { TrackedObject } from 'tracked-built-ins';
import { object } from 'yup';
import type { TemplateKeyForDidUpdateCallback } from '../certificate/certificate-details';
import CustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import SchoolSelect from 'tio-common/components/tuition-assistance/program-details/school-select';
import type { TasFieldsOrCustomFields, CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type Owner from '@ember/owner';
const number = Number;
export interface TuitionAssistanceFormsProgramInformationSignature {
    Args: {
        programInstance: TasProgramInstanceModel;
        selectedSchool?: TasUnifiedInstitutionModel;
        cancel?: () => void;
        saveAndNext?: (fieldsCopy: TasProgramInstanceModel['fields'], customFieldsCopy: TasProgramInstanceModel['customFields'], isFormValid: boolean) => void;
        saveForLater?: (fieldsCopy: TasProgramInstanceModel['fields'], customFieldsCopy: TasProgramInstanceModel['customFields']) => void;
        showActionButtons: boolean;
        didUpdate?: (field: TasFieldsOrCustomFields, key: TemplateKeyForDidUpdateCallback) => void;
        saveTaskIsRunning?: boolean;
        sectionValid?: (arg0: boolean) => void;
    };
}
export default class TuitionAssistanceFormsProgramInformation extends Component<TuitionAssistanceFormsProgramInformationSignature> {
    @service
    router: RouterService;
    @service
    store: typeof Store;
    customFieldsProvince = 'PROGRAM';
    fieldsCopy: TasProgramInstanceModel['fields'];
    @tracked
    customFieldsCopy: TasProgramInstanceModel['customFields'];
    @tracked
    selectedSchool?: TasUnifiedInstitutionModel;
    constructor(owner: Owner, args: TuitionAssistanceFormsProgramInformationSignature['Args']){
        super(owner, args);
        this.fieldsCopy = new TrackedObject(this.args.programInstance.fields);
        const programTemplateCustomFields = this.args.programInstance.tasProgramTemplate?.instanceCustomFields || [];
        this.customFieldsCopy = copyFieldsAndUpdatePerProgramTemplate(this.args.programInstance.customFields, programTemplateCustomFields);
        this.setSelectedSchoolIfExists();
        if (this.args.sectionValid) {
            this.args.sectionValid?.(this.isFormValid);
        }
    }
    setSelectedSchoolIfExists() {
        if (this.args.selectedSchool) {
            this.selectedSchool = this.args.selectedSchool;
        }
    }
    featuredSchoolData = trackedFunction(this, async ()=>{
        const template = this.args.programInstance.tasProgramTemplate;
        const schools = await this.store.query('tas-program-template-institution', {
            filter: {
                'is-featured': true,
                'tas-program-template': template.id
            },
            include: 'accredited-institution,unaccredited-institution,logo'
        });
        return schools;
    });
    get featuredSchools() {
        return this.featuredSchoolData.value || [];
    }
    get formModelForValidation() {
        const customFieldsFormModelForProvince = buildCustomFieldsFormModelForValidation(this.customFieldsForProvince);
        const programBegin: string = getSingleValueForTasField('ESTIMATED_PROGRAM_BEGIN', this.fieldsCopy);
        const programEnd: string = getSingleValueForTasField('ESTIMATED_PROGRAM_COMPLETION', this.fieldsCopy);
        return {
            SCHOOL_INSTITUTION_NAME: getSingleValueForTasField('SCHOOL_INSTITUTION_NAME', this.fieldsCopy),
            PROGRAM_SPECIALIZATION: getSingleValueForTasField('PROGRAM_SPECIALIZATION', this.fieldsCopy),
            PROGRAM_MAJOR: getSingleValueForTasField('PROGRAM_MAJOR', this.fieldsCopy),
            ESTIMATED_PROGRAM_BEGIN: this.formatDateForValidation(programBegin),
            ESTIMATED_PROGRAM_COMPLETION: this.formatDateForValidation(programEnd),
            ESTIMATED_COST: getSingleValueForTasField('ESTIMATED_COST', this.fieldsCopy),
            ENROLLMENT_OBJECTIVE: getSingleValueForTasField('ENROLLMENT_OBJECTIVE', this.fieldsCopy),
            ...customFieldsFormModelForProvince
        };
    }
    formatDateForValidation(date: string) {
        return date ? new Date(date) : undefined;
    }
    @cached
    get dynamicValidationSchema() {
        const fields = [
            {
                name: 'SCHOOL_INSTITUTION_NAME',
                rules: {
                    type: 'string'
                }
            },
            {
                name: 'PROGRAM_SPECIALIZATION',
                rules: {
                    type: 'string'
                }
            },
            {
                name: 'PROGRAM_MAJOR',
                rules: {
                    type: 'string'
                }
            },
            {
                name: 'ESTIMATED_PROGRAM_BEGIN',
                rules: {
                    type: 'date',
                    min: '2000-01-01',
                    max: '2099-01-01'
                },
                errors: {
                    min: 'Date must be after Jan 1, 2000',
                    max: 'Date must be before Jan 1, 2099'
                }
            },
            {
                name: 'ESTIMATED_PROGRAM_COMPLETION',
                rules: {
                    type: 'date',
                    min: 'ESTIMATED_PROGRAM_BEGIN',
                    max: '2099-01-01'
                },
                errors: {
                    min: 'Date must be after program begin date',
                    max: 'Date must be before Jan 1, 2099'
                }
            },
            {
                name: 'ESTIMATED_COST',
                rules: {
                    type: 'number',
                    typeError: 'Value must be a number'
                }
            },
            {
                name: 'ENROLLMENT_OBJECTIVE',
                rules: {
                    type: 'string'
                }
            }
        ];
        return buildValidationSchemaForProgramTemplateFields(// @ts-expect-error: not sure about this one
        fields, this.args.programInstance?.tasProgramTemplate);
    }
    @cached
    get dynamicValidationSchemaForCustomFields() {
        return buildValidationSchemaForCustomFields(this.customFieldsForProvince);
    }
    get customFieldsForProvince() {
        return this.customFieldsCopy.filter((field)=>field.province === this.customFieldsProvince);
    }
    get isFormValid() {
        return !this.formValidationErrors.length;
    }
    get formValidationErrors() {
        const schema = (this.dynamicValidationSchema || object()).concat(this.dynamicValidationSchemaForCustomFields || object());
        try {
            schema?.validateSync?.(this.formModelForValidation, {
                abortEarly: false
            });
        } catch (err) {
            return err.inner || [];
        }
        return [];
    }
    get isDependentProgram() {
        return this.args.programInstance.tasProgramTemplate.isDependentProgram;
    }
    @action
    updateValueForField(fieldName: FieldName, value: number, event?: Event) {
        let valueToSet = value;
        // @ts-expect-error: input mask
        if ((event?.target as HTMLInputElement)?.inputmask?.userOptions?.alias === 'currency') {
            // @ts-expect-error: input mask
            valueToSet = (event.target as HTMLInputElement).inputmask.unmaskedvalue() * 100;
        }
        // @ts-expect-error: not sure about this one
        setSingleValueForTasField(fieldName, valueToSet, this.fieldsCopy);
        this.didUpdateFields(this.fieldsCopy, 'tasProgramInstanceFields');
    }
    @action
    didUpdateCustomFields(updated: CustomFieldSignature[] = []) {
        this.customFieldsCopy = updated;
        this.didUpdateFields(this.customFieldsCopy, 'tasProgramInstanceCustomFields');
    }
    @action
    didUpdateFields(fields: TasProgramInstanceModel['fields'] | TasProgramInstanceModel['customFields'], key: TemplateKeyForDidUpdateCallback) {
        this.args.didUpdate?.(fields, key);
        this.args.sectionValid?.(this.isFormValid);
    }
    @action
    didSelectSchool(model?: TasUnifiedInstitutionModel) {
        this.selectedSchool = model;
        // @ts-expect-error: is the expectation that we can remove value or should we wrap in if?
        this.updateValueForField('SCHOOL_INSTITUTION_NAME', model?.locationName);
        // @ts-expect-error: is the expectation that we can remove value or should we wrap in if?
        this.updateValueForField('TAS_INSTITUTION_ID', model?.institutionId);
        // @ts-expect-error: is the expectation that we can remove value or should we wrap in if?
        this.updateValueForField('DAPIP_INSTITUTION_ID', model?.dapipId);
    }
    static{
        template_332e66b4b70748fa9825c4046f89ee04(`
    <div class={{if this.isDependentProgram "w-1/2" "grid md:grid-cols-3 w-full"}}>
      <div class="order-2 md:order-1 md:col-start-2 col-span-1">
        <form class="mx-8">
          <SchoolSelect
            @programTemplate={{@programInstance.tasProgramTemplate}}
            @selected={{this.selectedSchool}}
            @onChange={{this.didSelectSchool}}
            @errors={{errorsForField
              "SCHOOL_INSTITUTION_NAME"
              schemaErrors=this.formValidationErrors
            }}
            class="my-4"
          />
          <ProgramFormField
            @name="PROGRAM_SPECIALIZATION"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            {{#if field.isFieldOptionPicklist}}
              <FormSelect
                data-legacy-input
                id={{field.inputId}}
                {{! @glint-expect-error: not sure about this one }}
                @options={{field.fieldOptions}}
                @selected={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onChange={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                as |option|
              >
                {{! @glint-expect-error: not sure about this one }}
                {{option}}
              </FormSelect>
            {{else}}
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                @value={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
              />
            {{/if}}
          </ProgramFormField>
          <ProgramFormField
            @name="PROGRAM_MAJOR"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            {{#if field.isFieldOptionPicklist}}
              <FormSelect
                data-legacy-input
                id={{field.inputId}}
                {{! @glint-expect-error: how do we reconcile readonly }}
                @options={{field.fieldOptions}}
                @selected={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onChange={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                as |option|
              >
                {{! @glint-expect-error: not sure about this one }}
                {{option}}
              </FormSelect>
            {{else}}
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                @value={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
              />
            {{/if}}
          </ProgramFormField>
          <ProgramFormField
            @name="ESTIMATED_PROGRAM_BEGIN"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            <FormInput
              data-legacy-input
              id={{field.inputId}}
              type="date"
              @value={{getSingleValueForTasField field.name this.fieldsCopy}}
              {{! @glint-expect-error: not sure about this one }}
              @onInput={{fn this.updateValueForField field.name}}
              @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
            />
          </ProgramFormField>
          <ProgramFormField
            @name="ESTIMATED_PROGRAM_COMPLETION"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            <FormInput
              data-legacy-input
              id={{field.inputId}}
              type="date"
              @value={{getSingleValueForTasField field.name this.fieldsCopy}}
              {{! @glint-expect-error: not sure about this one }}
              @onInput={{fn this.updateValueForField field.name}}
              @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
            />
            <p class="text-xs text-tio-gray-600 mt-3">
              {{t "tuition_assistance.preapproval_app.program_hint"}}
            </p>
          </ProgramFormField>
          <ProgramFormField
            @name="ESTIMATED_COST"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            {{#if field.isFieldOptionPicklist}}
              <FormSelect
                data-legacy-input
                id={{field.inputId}}
                {{! @glint-expect-error: not sure about this one }}
                @options={{field.fieldOptions}}
                @selected={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onChange={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                as |option|
              >
                {{! @glint-expect-error: not sure about this one }}
                {{option}}
              </FormSelect>
            {{else}}
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                {{! initial value is the original field value, not the copy, because of inputmask on FormInput being funny }}
                @value="{{divide
                  (or
                    (number
                      (getSingleValueForTasField
                        field.name @programInstance.tasProgramTemplate.fields
                      )
                    )
                    0
                  )
                  100
                }}"
                {{! @glint-expect-error: not sure about this one }}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
              />
            {{/if}}
          </ProgramFormField>

          <ProgramFormField
            @name="ENROLLMENT_OBJECTIVE"
            @programTemplate={{@programInstance.tasProgramTemplate}}
            class="my-4"
            as |field|
          >
            {{#if field.isFieldOptionPicklist}}
              <FormSelect
                data-legacy-input
                id={{field.inputId}}
                {{! @glint-expect-error: not sure about this one }}
                @options={{field.fieldOptions}}
                @selected={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onChange={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                as |option|
              >
                {{! @glint-expect-error: not sure about this one }}
                {{option}}
              </FormSelect>
            {{else}}
              <FormTextarea
                data-legacy-input
                id={{field.inputId}}
                @value={{getSingleValueForTasField field.name this.fieldsCopy}}
                {{! @glint-expect-error: not sure about this one }}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
              />
            {{/if}}
          </ProgramFormField>

          <CustomFieldsForProvince
            @province={{this.customFieldsProvince}}
            @customFields={{this.customFieldsCopy}}
            @didUpdateFields={{this.didUpdateCustomFields}}
            @formValidationErrors={{this.formValidationErrors}}
            @fieldContainerClass="my-4"
            @hasSubmitted={{false}}
          />
        </form>
      </div>
      <div class="order-1 md:order-2 md:col-start-3 col-span-1">
        {{#if this.featuredSchools.length}}
          <FeaturedSchools
            @featuredSchools={{this.featuredSchools}}
            @isVertical={{screenIs "md"}}
            class="my-6"
          />
        {{/if}}
      </div>
    </div>

    {{#if @showActionButtons}}
      <ActionButtons
        @cancel={{@cancel}}
        {{! @glint-expect-error: not sure about this one }}
        @saveAndNext={{@saveAndNext}}
        {{! @glint-expect-error: not sure about this one }}
        @saveForLater={{@saveForLater}}
        @fieldsCopy={{this.fieldsCopy}}
        @customFieldsCopy={{this.customFieldsCopy}}
        @isFormValid={{this.isFormValid}}
        @saveTaskIsRunning={{@saveTaskIsRunning}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
