import { template as template_fd20f08497a343ae8a8226efafd1fdf1 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface S {
    Args: {
        nav: unknown;
    };
}
const AppSidebarNavGroupsEmployeePslfComponent: TOC<S> = template_fd20f08497a343ae8a8226efafd1fdf1(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.group @icon="support" @label="Loan Forgiveness" @nestedRoute="pslf" as |group|>
      <group.item @nestedRoute="dashboard.index" @label={{t "sidebar.pslf_dashboard"}} />
      <group.item @nestedRoute="dashboard.my-documents" @label={{t "sidebar.pslf_documents"}} />
      <group.item @nestedRoute="questions" @label={{t "sidebar.pslf_questions"}} />
      {{! @glint-expect-error: no idea}}
    </nav.group>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsEmployeePslfComponent;
