import { template as template_f81cf285294a4c7f97044ea81441570e } from "@ember/template-compiler";
import divide from 'ember-math-helpers/helpers/div';
import type AccountingPaymentModel from 'tio-common/models/accounting-payment';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface ContributionsAccountingPaymentsSummarySignature {
    Args: {
        model: AccountingPaymentModel;
    };
}
const ContributionsAccountingPaymentsSummaryComponent: TOC<ContributionsAccountingPaymentsSummarySignature> = template_f81cf285294a4c7f97044ea81441570e(`
    {{#if @model.total}}
      <p class="mx-2 mb-3">
        <span class="font-semibold text-base">
          {{safeFormatNumber (divide @model.total 100) style="currency" currency="USD"}}
        </span>
        {{t "contributions.total_to_date"}}
      </p>
    {{/if}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ContributionsAccountingPaymentsSummaryComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Contributions::AccountingPayments::Summary': typeof ContributionsAccountingPaymentsSummaryComponent;
        'contributions/accounting-payments/summary': typeof ContributionsAccountingPaymentsSummaryComponent;
    }
}
