import { template as template_0f64b21babea4a0b839b5b91472ab402 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TuitionAssistanceDashboardApplyToPrograms from 'tio-employee/components/tuition-assistance/dashboard/apply-to-programs';
import TuitionAssistanceDashboardMyPaymentActivity from 'tio-employee/components/tuition-assistance/dashboard/my-payment-activity';
import TuitionAssistanceDashboardMyPrograms from 'tio-employee/components/tuition-assistance/dashboard/my-programs';
import type { LimitsStatusDataSignature } from 'tio-common/services/tuition-assistance';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import { Header, Section, VStack } from 'tio-ui/components/layout';
export interface S {
    Args: {
        model: {
            isHeldParticipant: boolean;
            activeInstances: TasProgramInstanceModel[];
            templates: TasProgramTemplate[];
            pastInstances: TasProgramInstanceModel[];
            limitsStatus: LimitsStatusDataSignature;
        };
    };
}
export default RouteTemplate<S>(template_0f64b21babea4a0b839b5b91472ab402(`
    {{pageTitle (t "dashboard")}}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>

      {{#if @model.isHeldParticipant}}
        <TioAlert @type="warning" class="my-4" @allowDismiss={{true}}>
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/if}}
      <Section>
        <:header>
          {{t "tuition_assistance.dashboard.payment_activity.payments"}}
        </:header>
        <:body>
          <h3 class="font-semibold mt-4">
            {{t "tuition_assistance.dashboard.payment_activity.total_payments"}}
          </h3>
          <p>
            {{t "tuition_assistance.dashboard.payment_activity.note"}}
          </p>
          <TuitionAssistanceDashboardMyPaymentActivity
            class="m-2 mb-16"
            @limitsStatus={{@model.limitsStatus}}
          />
        </:body>
      </Section>
      <Section>
        <:body>
          <TuitionAssistanceDashboardMyPrograms
            @programStatus="active"
            @programInstances={{@model.activeInstances}}
            @sectionHeader={{t "tuition_assistance.dashboard.active_programs"}}
          />
        </:body>
      </Section>
      <Section>
        <:body>
          <TuitionAssistanceDashboardApplyToPrograms
            @programTemplates={{@model.templates}}
            @programInstances={{@model.activeInstances}}
            class="mb-12"
          />
        </:body>
      </Section>
      {{#if @model.pastInstances.length}}
        <Section>
          <:body>
            <TuitionAssistanceDashboardMyPrograms
              @programStatus="past"
              @programInstances={{@model.pastInstances}}
              @sectionHeader={{t "tuition_assistance.dashboard.past_programs"}}
              class="mb-12"
            />
          </:body>
        </Section>
      {{/if}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
