import { template as template_6a2efd4492524e3d97314268c8b7415f } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
const AppSidebarNavGroupsAdminEligibilityFilesComponent: TOC<Signature> = template_6a2efd4492524e3d97314268c8b7415f(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.item
      @nestedRoute="admin.eligibility-files"
      @icon="upload_file"
      @label={{t "sidebar.eligibility_files"}}
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsAdminEligibilityFilesComponent;
