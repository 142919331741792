import { template as template_0e8ba72c80584e6c8bdf2881fbe81279 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
export default RouteTemplate(template_0e8ba72c80584e6c8bdf2881fbe81279(`
    {{pageTitle (t "tuition_assistance.default")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
