import { template as template_ae27ddae53754f4a8cd584bfbfff3a89 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
interface SyfRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfRouteSignature>(template_ae27ddae53754f4a8cd584bfbfff3a89(`
    <VStack>
      <Header>{{t "syf.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
