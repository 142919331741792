import { template as template_1fc710c3683a4c709916ea217cda8c3c } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_1fc710c3683a4c709916ea217cda8c3c(`
    <VStack>{{outlet}}</VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
